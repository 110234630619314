import { Component, ElementRef, inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { condOperators, condOperatorsExt, condParse, condRec, NodeBasic } from '@ci';
import { NodeEditService } from '../node-edit/node-edit.service';

@Component({
  selector: 'hlds-node-edit-cond',
  templateUrl: './node-edit-cond.component.html',
  styleUrl: './node-edit-cond.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NodeEditCondComponent implements OnInit {
  @ViewChild('inputCond') inputCond!: ElementRef<HTMLInputElement>;
  @ViewChild('selectCompare') selectCompare!: MatSelect;
  readonly nes = inject(NodeEditService);
  readonly parent = this.nes.parent;

  //  eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  remove(varName: string) {
    const { usedUnused } = this.parent;

    usedUnused.used = usedUnused.used.filter((f) => f !== varName);
  }

  selectCompareChange(event: MatSelectChange) {
    const {
      inputCond: { nativeElement },
    } = this;
    const {
      controls: { cond },
    } = this.parent;
    const index = condOperatorsExt.indexOf(event.value); // should always succeed
    const op = condOperators[index < 0 ? 0 : index];
    const value = `${cond.value.trim()} ${op} `;

    cond.setValue(value);

    setTimeout(() => {
      nativeElement.focus(); // Focus on the input element and move the caret to the end.
      nativeElement.setSelectionRange(value.length, value.length);
    }, 250);
  }

  selectTrigger(descendents: NodeBasic[], id: number): string {
    return descendents.find((d) => d.id === id)?.label || '-';
  }

  tabChanged(event?: MatTabChangeEvent) {
    const {
      condition,
      controls: { cond, basicValue, basicOperator, basicVarName },
    } = this.parent;
    const noEvent = { emitEvent: false };

    if (event) {
      condition.selectedIndex = event.index;
    }

    condition.advanced = false;

    basicVarName.setValue('', noEvent);
    basicOperator.setValue('', noEvent);
    basicValue.setValue('', noEvent);

    if (cond.value.trim().length) {
      const parse = condParse(cond.value, { strict: false });

      this.parent.condition.advanced = parse.tokens.length > 3;

      if (!this.parent.condition.advanced) {
        for (const token of parse.tokens) {
          const {
            image,
            tokenType: { name, CATEGORIES },
          } = token;
          if (name === condRec.identifier.name) {
            basicVarName.setValue(image, noEvent);
          } else if (CATEGORIES?.length) {
            const category = CATEGORIES[0];
            if (category.name === condRec.compareOperator.name) {
              basicOperator.setValue(image, noEvent);
            } else if (category.name === condRec.value.name) {
              basicValue.setValue(image, noEvent);
            }
          }
        }
      }
    }
  }

  units(name: string): string {
    const row = this.parent.data.testRows.find((r) => r.varName == name);
    return row?.unit || '';
  }
}
