<mat-dialog-content class="h-[20rem]">
  <hlds-generic-table
    [items]="items"
    [rows]="sims"
    [options]="options"
    (run)="action($event.row, 'run')"
  ></hlds-generic-table>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <div class="flex flex-row mt-[-4px] border-solid border border-grayscale-300 p-2">
    <button mat-icon-button (click)="run(-9999)" [disabled]="index < 1">
      <mat-icon>first_page</mat-icon>
    </button>
    <button mat-icon-button (click)="run(-1)" [disabled]="index < 1">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button mat-icon-button (click)="run(1)" [disabled]="index >= model.data.sims.length - 1">
      <mat-icon>navigate_next</mat-icon>
    </button>
    <button mat-icon-button (click)="run(9999)" [disabled]="index >= model.data.sims.length - 1">
      <mat-icon>last_page</mat-icon>
    </button>
    @if (index >= 0) {
      <div class="mt-[14px]">{{ index + 1 }}/{{ model.data.sims.length }}</div>
    }
  </div>
  <button mat-button (click)="export()">
    {{ 'shared.button.export' | translate }}
  </button>
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.done' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #actionTemplate let-row="row">
  <div class="flex flex-row space-x-2">
    <button mat-icon-button (click)="action(row, 'run')">
      <mat-icon>{{ 'shared.icon.run' | translate }}</mat-icon>
    </button>
    <button mat-icon-button (click)="action(row, 'star')" [matTooltip]="'hlds.sim-history.tooltip.star' | translate">
      <mat-icon [ngClass]="{ star: row[starCol], filled: row[starCol] }">kid_star</mat-icon>
    </button>
    <button mat-icon-button (click)="action(row, 'delete')" [ngClass]="{ invisible: row[starCol] }">
      <mat-icon>{{ 'shared.icon.delete' | translate }}</mat-icon>
    </button>
  </div>
</ng-template>
