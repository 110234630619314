import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabModel, OrderNodeNote, OrderNodeStateModel, TreeClass } from '@ci';
import { SettingsService } from '../../services/settings/settings.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { genericTableOptions } from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { OrderDispositionComponentRes } from '../order-disposition/order-disposition.component.model';
import { OrderInterpComponentModel } from './order-interp.component.model';

@Component({
  templateUrl: './order-interp.component.html',
})
export class OrderInterpComponent extends BaseFormDirective<OrderDispositionComponentRes> implements OnInit {
  readonly dispositions = inject(SettingsService).data.algorithms.dispositions;
  readonly labItems: KeyValueMenuItem<keyof LabModel>[] = [
    { key: 'varName', value: { label: 'hlds.lab.table.col.varName' } },
    { key: 'value', value: { label: 'hlds.lab.table.col.value', align: 'right' } },
    { key: 'createdAt', value: { label: 'hlds.lab.table.col.createdAt', type: 'date' } },
    { key: 'updatedAt', value: { label: 'hlds.lab.table.col.updatedAt', type: 'date' } },
  ];
  readonly nodNotesItems: KeyValueMenuItem<keyof OrderNodeNote>[] = [
    { key: 'nodeLabel', value: { label: 'hlds.lab.table.col.varName' } },
    { key: 'note', value: { label: 'hlds.].note' } },
    { key: 'updatedAt', value: { label: 'hlds.lab.table.col.updatedAt', type: 'date' } },
  ];
  readonly nodeStateItems: KeyValueMenuItem<keyof OrderNodeStateModel>[] = [
    {
      key: 'nodeLabel',
      value: {
        label: 'hlds.node-edit.type.stop.ancestors.label',
      },
    },
    {
      key: 'nodeStatus',
      value: {
        label: 'hlds.order-action.table.col.nodeState',
      },
    },
    {
      key: 'createdAt',
      value: {
        label: 'hlds.order-action.table.col.createdAt',
        type: 'date',
      },
    },
  ];
  readonly options = genericTableOptions.noPagingNoFill;
  private readonly data: OrderInterpComponentModel = inject(MAT_DIALOG_DATA);
  readonly order = this.data.order;
  readonly algorithm = this.data.algorithm;
  readonly labsModel = this.data.labsModel;

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    this.formCreate({ disposition: '' });

    const { algorithm, order } = this;

    for (const note of order.nodeNotes) {
      const node = TreeClass.nodeFind(algorithm.tree.nodes, note.nodeId); // TODO gojs

      if (node) {
        note.nodeLabel = node.type === 'cond' ? node.cond : node.label;
      }
    }
  }
}
