<div class="node-edit-cond flex flex-col">
  @switch (parent.controls.type.value) {
    @case ('cond') {
      <ng-container *ngTemplateOutlet="cond_"></ng-container>
    }
    @case ('lab_panel') {
      <ng-container *ngTemplateOutlet="labPanel_"></ng-container>
    }
  }
</div>

<ng-template #labPanel_>
  <ng-container *ngTemplateOutlet="addTest_"></ng-container>
  <ng-container *ngTemplateOutlet="chips_"></ng-container>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.lab_panel.label' | translate }}</mat-label>
    <input [formControl]="parent.controls.label" matInput required />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.lab_panel.description' | translate }}</mat-label>
    <textarea rows="3" [formControl]="parent.controls.description" matInput [cdkTextareaAutosize]="true"></textarea>
  </mat-form-field>
  <div class="mb-4">
    <div>{{ 'hlds.node-edit.type.cond.autoReflex-heading' | translate }}</div>
    <mat-checkbox [formControl]="parent.controls.autoReflex"
      >{{ 'hlds.settings.algorithms.autoReflex' | translate }}
    </mat-checkbox>
  </div>
</ng-template>

<ng-template #cond_>
  <ng-container *ngTemplateOutlet="addTest_"></ng-container>
  <div class="grid grid-cols-[220px_1fr] gap-2 items-start">
    <ng-container *ngTemplateOutlet="chips_"></ng-container>
    <div class="flex flex-col">
      <mat-tab-group
        mat-stretch-tabs="false"
        selectedIndex="{{ parent.condition.selectedIndex }}"
        (selectedTabChange)="tabChanged($event)"
      >
        <mat-tab [bodyClass]="'mt-4'" [label]="'hlds.node-edit.type.cond.tab.basic' | translate">
          <ng-container *ngTemplateOutlet="condBasic_"></ng-container>
        </mat-tab>
        <mat-tab [bodyClass]="'mt-4'" [label]="'hlds.node-edit.type.cond.tab.advanced' | translate">
          <ng-container *ngTemplateOutlet="condAdvanced_"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.cond.label' | translate }}</mat-label>
    <input [formControl]="parent.controls.label" matInput />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.cond.description' | translate }}</mat-label>
    <textarea rows="3" [formControl]="parent.controls.description" matInput [cdkTextareaAutosize]="true"></textarea>
  </mat-form-field>
  <div class="flex flex-row space-x-2 items-center">
    <mat-checkbox [formControl]="parent.controls.autoReflex"
      >{{ 'hlds.settings.algorithms.autoReflex' | translate }}
    </mat-checkbox>
    <mat-icon [matTooltip]="'hlds.node-edit.type.cond.autoReflex-heading' | translate">{{
      'shared.icon.info' | translate
    }}</mat-icon>
  </div>
  @if (!(parent.controls.cFalse.value || parent.controls.cTrue.value)) {
    <div class="text-error-500">
      {{ 'hlds.node-edit.type.cond.true-false-help' | translate }}
    </div>
  }
  <ng-container *ngTemplateOutlet="condThenElse_"> </ng-container>
</ng-template>

<ng-template #addTest_>
  <mat-accordion class="mb-4">
    <mat-expansion-panel #addTest>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.test.table.add-tooltip' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      @if (addTest.expanded) {
        <div class="flex flex-col">
          <hlds-test-add
            [varNamesToExclude]="parent.usedUnused.all"
            (varNamesAdded)="nes.varNamesAdded($event, true)"
          ></hlds-test-add>
        </div>
      }
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
<ng-template #chips_>
  <div class="flex flex-col">
    @if (parent.usedUnused.used.length) {
      <div class="flex flex-row space-x-2 items-center">
        <div [innerHTML]="'hlds.node-edit.type.' + parent.controls.type.value + '.tests-used' | translate"></div>
        <mat-icon
          [matTooltip]="'hlds.node-edit.type.' + parent.controls.type.value + '.tests-used-tooltip' | translate"
          >{{ 'shared.icon.info' | translate }}</mat-icon
        >
      </div>
      <mat-chip-set class="mb-4">
        @for (varName of parent.usedUnused.used; track varName) {
          <mat-chip
            removable
            [matTooltip]="units(varName)"
            (click)="nes.clipBoardCond([varName])"
            (removed)="remove(varName)"
            >{{ varName }}
            <button matChipRemove>
              <mat-icon>{{ 'shared.icon.cancel' | translate }}</mat-icon>
            </button>
          </mat-chip>
        }
      </mat-chip-set>
    }
    <ng-container *ngTemplateOutlet="chipsUnused_"></ng-container>
  </div>
</ng-template>
<ng-template #chipsUnused_>
  @if (parent.usedUnused.unused.length) {
    <div class="flex flex-row space-x-2 items-center">
      <div [innerHTML]="'hlds.node-edit.type.' + parent.controls.type.value + '.tests-other' | translate"></div>
      <mat-icon
        [matTooltip]="'hlds.node-edit.type.' + parent.controls.type.value + '.tests-other-tooltip' | translate"
        >{{ 'shared.icon.info' | translate }}</mat-icon
      >
    </div>
    <mat-chip-set class="mb-4">
      @for (varName of parent.usedUnused.unused; track varName) {
        <mat-chip
          removable
          (click)="nes.clipBoardCond([varName])"
          [matTooltip]="units(varName)"
          (removed)="nes.varNamesAdded([varName], true)"
          >{{ varName }}
          <button matChipRemove>
            <mat-icon>{{ 'shared.icon.add' | translate }}</mat-icon>
          </button>
        </mat-chip>
      }
    </mat-chip-set>
  }
</ng-template>

<ng-template #condAdvanced_>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.cond.cond' | translate }}</mat-label>
    <textarea rows="3" #inputCond [formControl]="parent.controls.cond" matInput [cdkTextareaAutosize]="true"></textarea>
    <mat-error *ngIf="parent.controls.cond.value && parent.controls.cond.getError('error')">
      {{ parent.controls.cond.getError('error') }}
    </mat-error>
    <mat-hint *ngIf="!parent.controls.cond.value">{{ 'hlds.node-edit.type.cond.hint.cond' | translate }}</mat-hint>
  </mat-form-field>
  @if (parent.errorType === 'compare') {
    <mat-form-field class="w-[3rem] ml-[2rem] mt-[-1rem] hidden-select">
      <mat-select #selectCompare panelClass="hidden-select" (selectionChange)="selectCompareChange($event)">
        <mat-option
          class="smaller-font"
          *ngFor="let operator of parent.operatorsExt"
          [value]="operator"
          [innerHTML]="operator"
        ></mat-option>
      </mat-select>
    </mat-form-field>
  }
</ng-template>

<ng-template #condBasic_>
  <div class="flex flex-row space-x-2">
    <mat-form-field class="w-[20rem]">
      <mat-label>{{ 'hlds.node-edit.type.cond.basic.varName' | translate }}</mat-label>
      <input [formControl]="parent.controls.basicVarName" matInput />
    </mat-form-field>
    <mat-form-field class="w-[8rem]">
      <mat-label>{{ 'hlds.node-edit.type.cond.basic.operator' | translate }}</mat-label>
      <mat-select panelClass="select-800" [formControl]="parent.controls.basicOperator">
        @for (operator of parent.operators; track operator) {
          <mat-option [value]="operator">{{ operator }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-[8rem]">
      <mat-label>{{ 'hlds.node-edit.type.cond.basic.value' | translate }}</mat-label>
      <input [formControl]="parent.controls.basicValue" matInput />
    </mat-form-field>
  </div>
</ng-template>

<ng-template #condThenElse_>
  <div class="grid grid-cols-2 space-x-2">
    @for (tf of parent.falseTrue; track tf) {
      <div class="flex flex-col">
        <div class="flex flex-row justify-start items-center space-x-2">
          <mat-checkbox [formControl]="tf.control.enable">{{ tf.label.enable | translate }}</mat-checkbox>
          <mat-icon [matTooltip]="'hlds.node-edit.type.cond.true-false-help' | translate">{{
            'shared.icon.info' | translate
          }}</mat-icon>
        </div>
        @if (tf.control.enable.value) {
          <mat-form-field>
            <mat-label>{{ tf.label.edge | translate }}</mat-label>
            <input [formControl]="tf.control.edge" matInput required />
          </mat-form-field>
          @if (tf.descendents.length) {
            <mat-form-field>
              <mat-label>{{ tf.label.link | translate }}</mat-label>
              <mat-select panelClass="select-800" [formControl]="tf.control.link">
                <mat-select-trigger>{{ selectTrigger(tf.descendents, tf.control.link.value) }}</mat-select-trigger>
                <mat-option class="smaller-font is-flex nowrap" disabled>
                  <div class="grid grid-cols-[20%_80%] gap-2">
                    <div>{{ 'hlds.node-edit.type.cond.link.select.type' | translate }}</div>
                    <div>{{ 'hlds.node-edit.type.cond.link.select.label' | translate }}</div>
                  </div>
                </mat-option>
                @for (descend of tf.descendents; track descend) {
                  <mat-option class="smaller-font is-flex nowrap" [value]="descend.id">
                    <div class="grid grid-cols-[20%_80%] gap-2">
                      <div class="truncate">{{ 'hlds.node-edit.type.' + descend.type + '.radio' | translate }}</div>
                      <div class="truncate">{{ descend.label }}</div>
                    </div>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        }
      </div>
    }
  </div>
</ng-template>
