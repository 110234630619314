<form *ngIf="form" [formGroup]="form" class="flex flex-col">
  @for (test of tests; track test) {
    <mat-form-field class="smaller-font">
      <mat-label>{{ test }}</mat-label>
      <input
        matInput
        type="number"
        min="0"
        max="999999"
        step="0.01"
        [formControl]="controls[test]"
        (mouseenter)="highlight.emit(test)"
        (mouseleave)="highlight.emit('')"
      />
      @if (isNumber(controls[test].value)) {
        <mat-icon matSuffix class="cursor-pointer" (click)="controls[test].setValue(''); run('run')">
          {{ 'shared.icon.cancel' | translate }}</mat-icon
        >
      }
      <mat-hint *ngIf="highlightTestIds.includes(test) && highlightNode.type === 'cond'">
        {{ highlightNode.cond }}
      </mat-hint>
      <mat-error>{{ 'hlds.sim.error.number' | translate }}</mat-error>
    </mat-form-field>
  }
  <div class="grid grid-cols-2 gap-2">
    <button class="order-2" mat-flat-button (click)="run('run')" [disabled]="!form.valid">
      {{ 'hlds.sim.button.run' | translate }}
    </button>
    <button class="order-1" mat-button (click)="history()">
      {{ 'hlds.sim.button.history' | translate }}&nbsp;({{ sims.length }})
    </button>
    @if (missingNodes.length === 0) {
      <div class="order-3 flex flex-row items-center space-x-2 all-covered">
        <mat-icon>{{ 'shared.icon.check' | translate }}</mat-icon>
        <div class="text-sm">{{ 'hlds.sim.message.covered' | translate }}</div>
      </div>
    } @else {
      <button class="order-3" mat-button (click)="missing()">
        <span>{{ 'hlds.sim.button.missing' | translate }}</span
        >&nbsp;<span>({{ missingNodes.length }})</span>
      </button>
    }
    <button class="order-4" mat-button (click)="run('clear')">
      {{ 'hlds.sim.button.clear' | translate }}
    </button>
    <button class="order-5" mat-button (click)="run('history-clear')">
      {{ 'hlds.sim.button.clear-history' | translate }}
    </button>
  </div>
</form>
@if (engineRun.error.length) {
  <div class="flex flex-col mt-8 space-y-4">
    <pre>{{ engineRun.error }}</pre>
  </div>
} @else {
  <mat-card class="mt-4">
    <mat-card-header>
      <mat-card-title>{{ 'hlds.sim.status' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-important flex-col text-sm">
      @if (engineRun.orderVarNames.length) {
        <div>
          &bullet;&nbsp;
          <span translate [translateParams]="{ value: engineRun.orderVarNames.join(', ') }"
            >hlds.sim.message.orders</span
          >
        </div>
      }
      @if (engineRun.stopLabels.length) {
        <div>
          &bullet;&nbsp;
          <span translate [translateParams]="{ value: engineRun.stopLabels.join(', ') }">hlds.sim.message.stops</span>
        </div>
      }
      <div class="flex flex-row justify-end mt-4">
        <button mat-flat-button (click)="runDetails()">
          {{ 'hlds.sim.button.details' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
}
