import { inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NodeAny, NodeBasic, TreeClass } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { TreeService } from '../../services/tree/tree.service';
import { NodeEditComponent } from './node-edit.component';

@Injectable({
  providedIn: 'root',
})
export class NodeEditService {
  private readonly snackBar = inject(SnackBarService);
  private readonly translate = inject(TranslateService);
  private readonly treeService = inject(TreeService);

  private _parent!: NodeEditComponent;

  get parent(): NodeEditComponent {
    return this._parent;
  }

  set parent(parent: NodeEditComponent) {
    this._parent = parent;
  }

  clipBoardCond(varNames: string[]) {
    const {
      controls: { cond },
    } = this.parent;
    const value = cond.value.trim();
    const vName = varNames.slice(-1)[0];

    this.#clipboard([vName]);

    if (value.length === 0) {
      cond.setValue(vName);
    } else {
      const andOr = ['and', 'or', '&&', '||', '('];
      const match = andOr.some((op) => value.toLowerCase().endsWith(op));
      if (match) {
        cond.setValue(value + ' ' + vName);
      }
    }
  }

  conditionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { translate, treeService, parent } = this;
      const {
        formValue: { type },
        errors,
        usedUnused,
      } = parent;

      parent.errorType = '';

      if (type !== 'cond') {
        return null;
      }

      const error = treeService.conditionValidator(usedUnused.used, control.value);
      let message = '';

      if (error) {
        message = error.text;

        if (message === errors.compare) {
          parent.errorType = 'compare';
          setTimeout(() => {
            const { selectCompare } = parent;

            if (selectCompare) {
              selectCompare.open();
              selectCompare.focus();
            }
          }, 200);
        } else if (message === errors.value) {
          message = translate.instant('hlds.node-edit.type.cond.error.type.value');
        } else if (message === errors.varNotFound && error.ids?.length) {
          const newVarNames = this.varNamesAdded(error.ids);

          if (newVarNames.length === error.ids.length) {
            return null;
          }
        }

        if (!error.noTranslate) {
          message = translate.instant(message, { ids: error.ids });
        }
      }

      return message && parent.errorType != 'compare' ? { error: message } : null;
    };
  }

  descendents(nodes: NodeAny[], node: NodeAny): NodeBasic[] {
    const result: NodeBasic[] = TreeClass.treeDescendentsSafe(nodes, node).map((n) => TreeClass.nodeBasic(n));

    result.sort((a, b) => a.type.localeCompare(b.type) || a.label.localeCompare(b.label));

    const match = result.findIndex((result) => result.id === node.id);

    // Don't include node in the result
    if (match >= 0) {
      result.splice(match, 1);
    }

    return result;
  }

  setUsedUnused(used?: string[]) {
    const {
      usedUnused,
      data: { testRows },
    } = this.parent;

    if (used) {
      usedUnused.used = used;
    }

    usedUnused.unused = testRows.filter((r) => !usedUnused.used.includes(r.varName)).map((r) => r.varName);

    usedUnused.all = [...usedUnused.used, ...usedUnused.unused];
  }

  varNamesAdded(vNames: string[], fromAdd = false): string[] {
    const {
      allTests,
      data: { testRows },
      usedUnused,
    } = this.parent;
    const result: string[] = [];

    for (const id of vNames.map((id) => id.toLowerCase())) {
      const test = allTests.find((v) => v.varName.toLowerCase() === id);

      if (test) {
        const { varName } = test;

        result.push(varName);

        if (!testRows.find((t) => t.varName == varName)) {
          testRows.push(test);
        }

        if (!usedUnused.used.includes(varName)) {
          usedUnused.used.push(varName);
        }
      }
    }

    this.setUsedUnused();

    if (fromAdd) {
      this.clipBoardCond(vNames);
    }

    return result;
  }

  #clipboard(varNames: string[]) {
    const { translate, snackBar, parent } = this;
    const { condition, controls } = parent;

    if (condition.selectedIndex === 0) {
      controls.basicVarName.setValue(varNames[0]);
    }

    if (!varNames.every((name) => !name)) {
      snackBar.open({
        message: translate.instant('shared.clipboard.copied'),
        messages: varNames,
        type: 'success',
      });

      navigator.clipboard.writeText(varNames.join(', '));
    }
  }
}
