import { AfterViewInit, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EngineClass, EngineRecord } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { EngineService } from '../../services/engine/engine.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { BaseSubscriptionsDirective } from '../../shared/base-subscriptions/base-subscriptions.directive';
import { GenericTableComponent } from '../../shared/generic-table/generic-table.component';
import { GenericTableOptions } from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { AlgorithmSimHistoryComponentModel } from './algorithm-sim-history.component.model';

@Component({
  templateUrl: './algorithm-sim-history.component.html',
  styleUrl: './algorithm-sim-history.component.scss',
})
export class AlgorithmSimHistoryComponent
  extends BaseSubscriptionsDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;
  @ViewChild(GenericTableComponent) genericTable!: GenericTableComponent;

  readonly model: AlgorithmSimHistoryComponentModel = inject(MAT_DIALOG_DATA);
  index = -1;
  readonly items: KeyValueMenuItem[] = [];
  readonly options: GenericTableOptions = {
    paging: 'none',
    noFill: true,
    enableRowClick: true,
  };
  sims = this.model.data.sims;
  readonly starCol = EngineClass.col.star;
  private readonly engineService = inject(EngineService);
  private readonly snackBar = inject(SnackBarService);
  private readonly translate = inject(TranslateService);

  action(row: EngineRecord, action: 'run' | 'delete' | 'star') {
    const { sims } = this.model.data;
    const index = sims.findIndex((r) => r === row);

    if (index < 0) {
      return; // shouldn't happen
    }

    switch (action) {
      case 'delete':
        sims.splice(index, 1);
        this.sims = sims.slice();
        break;
      case 'run':
        this.index = index;
        this.run(0, false);
        break;
      case 'star':
        sims[index][this.starCol] = row[this.starCol] = !row[this.starCol];
        break;
      default:
        throw new Error(`Unexpected action: ${action}`);
    }
  }

  export() {
    const {
      engineService,
      snackBar,
      translate,
      model: {
        data: { name, tests, sims },
      },
    } = this;
    const dateStr = DateTime.now().toFormat('yyyy-LL-dd HH_mm'); // can't use colon/:
    const cols = [...tests, this.starCol, EngineClass.col.result];
    const anchor: HTMLAnchorElement = document.createElement('a');
    const fileName = `${name} ${dateStr}.csv`;
    const rows: string[] = [];

    for (const sim of sims) {
      const row: string[] = [];

      for (const col of cols) {
        row.push(engineService.toString(sim[col]));
      }
      rows.push(row.join(',') + '\n');
    }

    const file = new Blob([cols.join(',') + '\n', ...rows], { type: 'text/plain' });

    anchor.download = fileName;
    anchor.href = URL.createObjectURL(file);
    anchor.click();
    anchor.remove();

    snackBar.open({
      message: translate.instant('hlds.sim.message.export', { fileName }),
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.run(0));

    const { sort } = this.genericTable;

    if (sort) {
      sort.sortChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        setTimeout(() => this.run(0));
      });
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    // Cleanup crap columns
    for (const sim of this.model.data.sims) {
      delete sim[GenericTableComponent.idCol];
    }
  }

  ngOnInit() {
    const {
      model: {
        data: { tests },
      },
      items,
    } = this;
    const resultCol = EngineClass.col.result;

    for (const test of tests) {
      items.push({
        key: test,
        value: { label: test, align: 'right', unsortable: true },
      });
    }

    items.push(
      {
        key: resultCol,
        value: { label: resultCol },
      },
      {
        key: 'actions',
        value: {
          label: 'shared.table.col.action',
          unsortable: true,
          template: () => this.actionTemplate,
          type: 'template',
        },
      },
    );
  }

  run(increment: number, scroll = true) {
    const { genericTable } = this;

    const data = genericTable.sortedData();
    const index = (this.index = Math.max(0, Math.min(data.length - 1, this.index + increment)));

    this.model.run$.next(data[index] as EngineRecord);
    genericTable.idSelected = data[index][genericTable.idCol];

    if (scroll) {
      const element: Element | null = document.getElementById(genericTable.idSelected);

      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'center' }), 250);
      }
    }
  }
}
