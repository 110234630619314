<div [ngClass]="{ 'flex-fill-8': !baseOptions.noFill }" class="min-w-full w-full p-1">
  <ng-container *ngTemplateOutlet="topIcons"></ng-container>
  <div
    *ngIf="selected.length > 0 && !baseOptions?.hideBatchButton"
    class="flex flex-row justify-end items-center space-x-2"
  >
    <button
      class="mini"
      mat-flat-button
      [matTooltip]="'shared.table.batch.tooltip' | translate"
      (click)="action('run', selected)"
    >
      <span>{{ 'shared.button.batch' | translate }}</span>
      <span class="counts">{{ selected.length }}</span>
    </button>
    <button *ngIf="idCol" mat-icon-button (click)="action('copyId', selected)">
      <mat-icon [matTooltip]="'shared.clipboard.tooltip.ids' | translate">{{
        'shared.icon.copyId' | translate
      }}</mat-icon>
    </button>
  </div>
  <div [ngClass]="{ 'flex-1': !baseOptions.noFill }" class="mat-elevation-z1 overflow-auto">
    <ng-container *ngTemplateOutlet="table"></ng-container>
    <div class="text-center" *ngIf="busy$ | async">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
<ng-template #table>
  <table mat-table [dataSource]="dataSource" class="generic-table" matSort matSortDisableClear>
    <ng-container [matColumnDef]="'select'">
      <th *matHeaderCellDef mat-header-cell>
        <mat-checkbox
          (change)="$event ? selectMasterToggle() : null"
          [checked]="selection.hasValue() && selectIsAllSelected()"
          [indeterminate]="selection.hasValue() && !selectIsAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td *matCellDef="let row" mat-cell>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
      <ng-container *ngIf="baseOptions?.paging !== 'none'">
        <td *matFooterCellDef [attr.colspan]="displayedColumns.length" mat-footer-cell>
          <mat-paginator
            [pageSizeOptions]="[5, 10, 25, 50, 100, 200]"
            [pageSize]="pageSize"
            showFirstLastButtons
          ></mat-paginator>
        </td>
      </ng-container>
    </ng-container>
    @for (item of items; track item.key) {
      <ng-container [matColumnDef]="item.key">
        <th
          *matHeaderCellDef
          [disabled]="!!item.value.unsortable"
          [ngClass]="{
            'th-right': item.value.align === 'right',
            'th-center': item.value.align === 'center',
          }"
          mat-header-cell
          mat-sort-header
        >
          @if (item.value.noTranslate) {
            {{ item.value.label || '' }}
          } @else {
            {{ item.value.label || '' | translate }}
          }
        </th>
        <td
          *matCellDef="let row"
          [ngClass]="{
            'td-right': item.value.align === 'right',
            'td-center': item.value.align === 'center',
            'color-link': item.value.color === 'link',
          }"
          [class]="baseOptions.getCss ? baseOptions.getCss(row, item) : ''"
          mat-cell
        >
          <ng-container
            *ngTemplateOutlet="
              dataTD;
              context: {
                item: item,
                row: row,
                value: row[item.key],
              }
            "
          ></ng-container>
        </td>
      </ng-container>
    }

    <ng-container [matColumnDef]="'action'">
      <th *matHeaderCellDef mat-header-cell>
        <ng-container *ngTemplateOutlet="actionTH"></ng-container>
      </th>
      <td *matCellDef="let row" mat-cell>
        @if (!(idCol && disabledIds.includes(row[idCol]))) {
          <ng-container *ngTemplateOutlet="actionTD; context: { $implicit: row }"></ng-container>
        } @else {
          {{ disabledMessage || 'shared.table.disabled' | translate }}
        }
      </td>
    </ng-container>
    <tr
      *matHeaderRowDef="displayedColumns; sticky: true"
      [ngClass]="{ hidden: baseOptions.hideHeaders }"
      mat-header-row
    ></tr>
    <tr
      *matRowDef="let row; columns: displayedColumns"
      mat-row
      [class]="baseOptions.getCss ? baseOptions.getCss(row) : ''"
      [ngClass]="{
        active: row[idCol] === idSelected,
        disabledRow: disabledIds.includes(row[idCol]),
      }"
      [id]="row[idCol]"
      (click)="action0(row, $event)"
    ></tr>
    <ng-container *ngIf="baseOptions.paging !== 'none'">
      <tr *matFooterRowDef="['select']; sticky: true" mat-footer-row></tr>
    </ng-container>
    <tr class="mat-row" *matNoDataRow>
      <td *ngIf="(busy$ | async) === false" class="mat-cell text-center" colspan="999" [translate]="noDataMessage"></td>
    </tr>
  </table>
</ng-template>

<!-- action <th> -->
<ng-template #actionTH>
  <div class="flex flex-row place-content-center">
    <div>{{ 'shared.table.col.action' | translate }}</div>
  </div>
</ng-template>

<!-- action body -->
<ng-template #actionTD let-row>
  <div class="flex flex-row place-content-center" *ngIf="baseOptions.actions">
    @for (act of baseOptions.actions; track act.action) {
      <button
        (click)="action(act.action, row); $event.stopPropagation()"
        [matTooltip]="act.tooltip || '' | translate"
        mat-icon-button
      >
        <mat-icon>{{ act.icon | translate }}</mat-icon>
      </button>
    }
  </div>
</ng-template>
<ng-template #dataTD let-item="item" let-itemValue="item.value" let-row="row" let-rowValue="value">
  @switch (itemValue.type) {
    @case (menuItemRecord.template) {
      <ng-container
        [ngTemplateOutlet]="itemValue.template()"
        [ngTemplateOutletContext]="{ row: row, item: item }"
      ></ng-container>
    }
    @case (menuItemRecord.id) {
      <ng-container *ngIf="rowValue">
        <button
          *ngIf="itemValue.copyId"
          mat-icon-button
          [matTooltip]="allActions.copyId.tooltip || '' | translate"
          [matTooltipPosition]="'above'"
          (click)="actionCopyId(rowValue); $event.stopPropagation()"
        >
          <mat-icon>{{ allActions.copyId.icon | translate }}</mat-icon>
        </button>
        <div class="mono inline-block" [matTooltip]="rowValue" [matTooltipPosition]="'above'">
          {{ rowValue }}
        </div>
      </ng-container>
    }
    @case (menuItemRecord.array) {
      @if (rowValue?.length) {
        {{ rowValue.join(', ') }}
      }
    }
    @case (menuItemRecord.boolean) {
      {{ (rowValue ?? '').toString() | titlecase }}
    }
    @case (menuItemRecord.date) {
      @if (rowValue?.length === 10) {
        {{ rowValue | date: ('shared.dateTime.shared.date.' + dateFormat | translate) }}
      } @else {
        {{ rowValue | date: ('shared.dateTime.shared.dateTime.' + dateFormat | translate) }}
      }
    }
    @case (menuItemRecord.dateOnly) {
      {{ rowValue | date: ('shared.dateTime.shared.date.' + dateFormat | translate) }}
    }
    @case (menuItemRecord.link) {
      @if (rowValue.startsWith(httpsStarts)) {
        <a mat-button [href]="rowValue" target="_blank" [matTooltip]="rowValue"> {{ rowValue }}</a>
      } @else {
        <div>{{ rowValue }}</div>
      }
    }
    @case (menuItemRecord.chip) {
      <mat-chip-set>
        <mat-chip [class]="chipClass(rowValue, itemValue.chipMap)">{{ rowValue | titlecase }}</mat-chip>
      </mat-chip-set>
    }
    @case (menuItemRecord.arrayLength) {
      <div>{{ rowValue?.length ?? 0 }}</div>
    }
    @case (menuItemRecord.titleCase) {
      <div>{{ rowValue | titlecase }}</div>
    }
    @default {
      <ng-container *ngTemplateOutlet="genericTD"></ng-container>
    }
  }
  <ng-template #genericTD>
    <div>{{ rowValue }}</div>
  </ng-template>
</ng-template>

<ng-template #topIcons>
  <div
    *ngIf="baseOptions.add || baseOptions.import || baseOptions.download"
    class="space-x-2 flex flex-row justify-end items-center"
  >
    <button
      *ngIf="baseOptions.import"
      mat-icon-button
      [matTooltip]="baseOptions.tooltip?.import || allActions.import.tooltip | translate"
      (click)="action('import', [])"
    >
      <mat-icon class="action-blue filled">{{ allActions.import.icon | translate }}</mat-icon>
    </button>
    <button
      *ngIf="baseOptions.add"
      mat-icon-button
      [matTooltip]="baseOptions.tooltip?.add || allActions.add.tooltip | translate"
      (click)="action('add', [])"
    >
      <mat-icon class="action-blue filled">{{ allActions.add.icon | translate }}</mat-icon>
    </button>
    <button
      *ngIf="baseOptions.download"
      mat-icon-button
      [matTooltip]="baseOptions.tooltip?.download || allActions.download.tooltip | translate"
      (click)="action('download', [])"
    >
      <mat-icon class="action-blue filled">{{ allActions.download.icon | translate }}</mat-icon>
    </button>
  </div>
</ng-template>
