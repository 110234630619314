import { inject, Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, SnapshotAction } from '@angular/fire/compat/database';
import { PatientModel } from '@ci';
import { map, Observable, of, take } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  private readonly fireDb = inject(AngularFireDatabase);
  private readonly fireRef: AngularFireList<PatientModel> = this.fireDb.list('/patients');
  private _rows!: PatientModel[];

  get rows(): PatientModel[] {
    return this._rows;
  }

  getCollection(): Observable<PatientModel[]> {
    const { fireRef } = this;

    if (this.rows) {
      return of(this.rows);
    }

    return fireRef.snapshotChanges().pipe(
      map((actions: SnapshotAction<PatientModel>[]) =>
        actions.map(
          (action: SnapshotAction<PatientModel>) =>
            ({
              id: action.payload.key,
              ...action.payload.val(),
            }) as PatientModel,
        ),
      ),
      tap((rows) => (this._rows = rows.sort((a, b) => a.name.localeCompare(b.name)))),
      take(1),
    );
  }

  update(patient: PatientModel) {
    this.fireRef.set(patient.id, patient).then();
  }
}
