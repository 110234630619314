import { DestroyRef, Directive, inject, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * RxJS related stuff. Destroy subscriptions to eliminate memory leaks.
 */
@Directive()
export class BaseSubscriptionsDirective implements OnDestroy {
  readonly busy$ = new BehaviorSubject<boolean>(false);
  readonly destroyRef = inject(DestroyRef);
  readonly searchBusy$ = new BehaviorSubject<boolean>(false);

  ngOnDestroy(): void {
    const { busy$, searchBusy$ } = this;

    busy$.next(false);
    busy$.complete();
    searchBusy$.next(false);
    searchBusy$.complete();
  }
}
