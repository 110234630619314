<div class="router-outlet algorithm-edit">
  <hlds-route-title [title]="'hlds.algorithm.back' | translate" [route]="routePath.algorithms"></hlds-route-title>
  <div class="text-center text-2xl">
    {{ controls.name.value }}
  </div>
  <mat-tab-group
    #tabGroup
    (selectedIndexChange)="actionTabChanged($event)"
    mat-stretch-tabs="false"
    class="flex-1 flex flex-col mat-tab-margin mat-tab-100"
  >
    <mat-tab [label]="'hlds.algorithm.tabs.tree' | translate">
      <ng-container *ngTemplateOutlet="grid_"></ng-container>
    </mat-tab>
    <mat-tab [label]="'hlds.algorithm.tabs.interps' | translate">
      <ng-container *ngTemplateOutlet="interps_"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #grid_>
  <div [ngClass]="{ hidden: !row.id }" class="h-full flex flex-col overflow-auto">
    <ng-container *ngTemplateOutlet="accordions_"></ng-container>
    <mat-drawer-container autosize class="flex-important flex-1 flex-col">
      <mat-drawer #drawer mode="side" (openedChange)="drawOpenClose(true)">
        <ng-container *ngTemplateOutlet="simulate_"></ng-container>
      </mat-drawer>
      <mat-drawer-content class="flex-important flex-col flex-1">
        <div class="flex-1 flex flex-col">
          <ng-container *ngTemplateOutlet="tree_"> </ng-container>
          <ng-container *ngTemplateOutlet="buttons_"></ng-container>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</ng-template>

<ng-template #interps_>
  <div class="h-full flex flex-col space-y-4">
    <!--
    <ng-container *ngTemplateOutlet="interpsAccordion_"></ng-container>
    -->
    @if (tree.forest && stopMapping.cols.length > 0) {
      <div class="flex flex-col">
        <ng-container *ngTemplateOutlet="mapping_"></ng-container>
      </div>
    }
    <div class="flex-1 flex flex-col">
      <ng-container *ngTemplateOutlet="treeSimple_"></ng-container>
      <ng-container *ngTemplateOutlet="buttons_"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #simulate_>
  @if (drawerOpened) {
    <div class="pl-4 pt-2">
      <hlds-dialog-drawer-close
        [heading]="'hlds.algorithm.button.simulate' | translate"
        [sidePanel]="true"
        (closed)="drawer.close()"
      ></hlds-dialog-drawer-close>
    </div>
    <hlds-algorithm-sim
      [row$]="row$"
      [simHighlight$]="simHighlight$"
      [simValues$]="simValues$"
      (highlight)="simHighlight($event)"
    ></hlds-algorithm-sim>
  }
</ng-template>

<ng-template #accordions_>
  <div class="mb-4" [ngClass]="{ hidden: drawerOpened }">
    <mat-accordion class="grid grid-cols-[30%_40%_30%] items-start" [displayMode]="'flat'">
      <ng-container *ngTemplateOutlet="nameDescription_"> </ng-container>
      <ng-container *ngTemplateOutlet="tests_"></ng-container>
      <ng-container *ngTemplateOutlet="stops_"></ng-container>
    </mat-accordion>
  </div>
</ng-template>

<ng-template #buttons_>
  <div class="mt-4 mb-4 flex flex-row justify-end items-center space-x-2">
    @if (row.tests.length && selectedIndex === 0) {
      <button mat-button (click)="drawer.toggle()">
        {{ 'hlds.algorithm.button.simulate' | translate }}
      </button>
    }
    @if (!readOnly) {
      <button mat-button (click)="actionReset()">
        {{ 'shared.button.reset' | translate }}
      </button>
      @if (selectedIndex === 0) {
        <button
          mat-button
          [disabled]="!form.valid || tree.status || testGrid.rows.length === 0"
          (click)="actionPublish()"
        >
          {{ 'shared.button.publish' | translate }}
        </button>
      }
      <button mat-flat-button [disabled]="!form.valid" (click)="actionSave()" [ngClass]="{ glow: dirty }">
        {{ 'shared.button.save' | translate }}
      </button>
    }
  </div>
</ng-template>

<ng-template #nameDescription_>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ controls.name.value }}
      </mat-panel-title>
      <mat-panel-description>
        {{ controls.description.value }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>{{ 'hlds.algorithms.table.col.name' | translate }}</mat-label>
        <input [formControl]="controls.name" matInput required [readonly]="readOnly" />
        <mat-error *ngIf="controls.name.hasError('required')">
          {{ 'shared.error.field-is-required' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'hlds.algorithms.table.description-opt' | translate }}</mat-label>
        <textarea
          matInput
          rows="3"
          [formControl]="controls.description"
          [readonly]="readOnly"
          [cdkTextareaAutosize]="true"
        ></textarea>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #tests_>
  <mat-expansion-panel (opened)="zoom$.next()" (closed)="zoom$.next()">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'hlds.algorithm.tests.title' | translate }}</mat-panel-title>
      @if (testGrid.rows.length) {
        <mat-panel-description>{{ row.tests.join(', ') }}</mat-panel-description>
      } @else {
        <mat-panel-description class="error">{{ 'hlds.algorithm.error.add-tests' | translate }}</mat-panel-description>
      }
    </mat-expansion-panel-header>
    <hlds-generic-table
      [items]="testGrid.items"
      [rows]="testGrid.rows"
      [options]="optionsTestGrid"
      (run)="actionTest($event)"
    ></hlds-generic-table>
  </mat-expansion-panel>
</ng-template>

<ng-template #tree_>
  @if (testGrid.rows.length === 0) {
    <div class="text-accent-500 font-[500] text-xl">
      {{ 'hlds.algorithm-edit-test-add.heading' | translate }}
    </div>
  }
  <mat-card class="p-2 space-y-4 flex flex-col flex-1 justify-items-center">
    <div class="flex flex-row items-center">
      @if (!readOnly) {
        <button mat-icon-button (click)="actionEditTree()" [matTooltip]="'hlds.algorithm.tree.edit' | translate">
          <mat-icon class="text-primary-500">{{ 'shared.icon.edit' | translate }}</mat-icon>
        </button>
      }
      @if (tree.forest) {
        <mat-icon
          class="pt-[2px]"
          [matTooltip]="
            (row.tree.serial ? 'hlds.algorithm.tree.trees.serial' : 'hlds.algorithm.tree.trees.not-serial') | translate
          "
          >{{ 'shared.icon.info' | translate }}</mat-icon
        >
      }
      @if (drawerOpened) {
        <div class="text-xs pl-2 pt-[3px]" [innerHTML]="'hlds.algorithm.simulate-message' | translate"></div>
      }
      <div class="pl-4 text-accent-500 font-[500]">
        @if (testGrid.rows.length) {
          <span>{{ tree.status }}</span>
        }
      </div>
    </div>
    <ng-container *ngTemplateOutlet="nodeView_"></ng-container>
  </mat-card>
</ng-template>

<ng-template #treeSimple_>
  <mat-card class="p-2 space-y-4 flex-1 flex flex-col">
    <ng-container *ngTemplateOutlet="nodeView_"></ng-container>
  </mat-card>
</ng-template>

<ng-template #countTemplate let-row="row">
  <button mat-mini-fab (click)="$event.stopPropagation(); actionCount(row)" [disabled]="row.count === 0">
    {{ row.count }}
  </button>
</ng-template>

<ng-template #nodeView_>
  <div class="flex-1">
    <hlds-node-view
      [editNode$]="editNode$"
      [nodeHighlight$]="nodeHighlight$"
      [nodes]="row.tree.nodes"
      [simHighlight$]="simHighlight$"
      [simValues$]="simValues$"
      [treeSerial]="row.tree.serial"
      [zoom$]="zoom$"
    ></hlds-node-view>
  </div>
</ng-template>

<ng-template #mapping_>
  <mat-card class="flex-1">
    <mat-card-header>
      <mat-card-title-group class="items-center">
        <mat-card-title>
          <div class="flex flex-row items-center space-x-2">
            <div>{{ 'hlds.interp-mapping.title' | translate }}</div>
            <mat-icon [matTooltip]="'hlds.interp-mapping.tooltip.title' | translate">{{
              'shared.icon.info' | translate
            }}</mat-icon>
          </div>
        </mat-card-title>
        <button
          mat-icon-button
          [matTooltip]="'hlds.interp-mapping.tooltip.add' | translate"
          (click)="mappingAction('add')"
        >
          <mat-icon class="action-blue filled">{{ 'shared.icon.add' | translate }}</mat-icon>
        </button>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content #mappingElement class="h-[500px] overflow-auto space-y-4">
      @for (richEntry of interpRich.richEntries; track richEntry; let cardIndex = $index) {
        <ng-container *ngTemplateOutlet="mappingCard_; context: { $implicit: cardIndex }"></ng-container>
      }
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #mappingCard_ let-cardIndex>
  <mat-card>
    <mat-card-content>
      <div class="flex flex-row justify-end items-center">
        <button
          mat-icon-button
          [matTooltip]="'hlds.interp-mapping.tooltip.duplicate' | translate"
          (click)="mappingAction('duplicate', cardIndex)"
        >
          <mat-icon>{{ 'shared.icon.duplicate' | translate }}</mat-icon>
        </button>
        <button
          mat-icon-button
          [matTooltip]="'hlds.interp-mapping.tooltip.delete' | translate"
          (click)="mappingAction('delete', cardIndex)"
        >
          <mat-icon>{{ 'shared.icon.delete' | translate }}</mat-icon>
        </button>
      </div>
      <div class="flex flex-row space-x-4">
        <mat-icon>{{ 'hlds.algorithm-node.icon.stop' | translate }}</mat-icon>
        <div class="flex-1">
          <div class="grid grid-cols-[46%_4%_46%_4%] items-baseline justify-items-center gap-2 max-w-[90rem]">
            @for (col of stopMapping.cols; track col; let index = $index, last = $last) {
              <mat-form-field>
                <mat-label>{{ stopMapping.rootTitles[index] }}</mat-label>
                <mat-select
                  multiple
                  panelClass="select-800"
                  (opened)="mappingHighlight(cardIndex, index, true)"
                  [formControl]="interpRich.richEntries[cardIndex].controls.groups[index]"
                >
                  <mat-select-trigger>{{ interpRich.richEntries[cardIndex].triggers[index] }}</mat-select-trigger>
                  @for (map of col; track map) {
                    <mat-option class="smaller-font is-flex" [value]="map.stop.id">
                      <div class="grid grid-cols-[40%_60%] text-xs items-center gap-x-1">
                        <div>
                          {{ map.stop.label }}
                        </div>
                        <div>
                          {{ map.stop.description }}
                        </div>
                      </div>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              @if (!last) {
                <div>{{ 'hlds.interp-mapping.and' | translate }}</div>
              }
            }
          </div>
          <div class="mb-4">
            <mat-divider></mat-divider>
          </div>
          <div class="grid grid-cols-2 items-start gap-4 max-w-[80rem]">
            <mat-form-field>
              <mat-label>{{ 'hlds.node-edit.type.stop.label' | translate }}</mat-label>
              <input
                matInput
                [readonly]="readOnly"
                required
                [formControl]="interpRich.richEntries[cardIndex].controls.label"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'hlds.node-edit.type.stop.description' | translate }}</mat-label>
              <textarea
                matInput
                [readonly]="readOnly"
                rows="3"
                [cdkTextareaAutosize]="true"
                [formControl]="interpRich.richEntries[cardIndex].controls.description"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #stops_>
  <mat-expansion-panel (opened)="initStopGrid(); zoom$.next()" (closed)="zoom$.next()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'hlds.interp.accordion.title' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <hlds-generic-table
      [items]="stopGrid.items"
      [rows]="stopGrid.rows"
      [options]="optionsStopGrid"
      (run)="actionStop($event)"
    ></hlds-generic-table>
  </mat-expansion-panel>
</ng-template>

<!--
<ng-template #interpsAccordion_>
  <mat-accordion [displayMode]="'flat'">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'hlds.interp.accordion.comments' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <hlds-generic-table
        [items]="interpGrid.items"
        [rows]="row.interps"
        [options]="optionsInterGrid"
        (run)="actionInterp($event)"
      ></hlds-generic-table>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
-->
