<hlds-dialog-drawer-close [heading]="'hlds.order-details.title' | translate"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex flex-col space-y-4 order-details">
  @for (rows of gridModelRows; track rows) {
    <hlds-generic-table [items]="items" [rows]="rows" [options]="options"></hlds-generic-table>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.ok' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #valuesTemplate let-row="row">
  <div class="flex flex-col">
    @for (value of row.values; track value) {
      <div>{{ value }}</div>
    }
  </div>
</ng-template>
