<div class="flex flex-col">
  <div *ngIf="data.message">
    @if (data.translateMessage) {
      {{ data.message | translate }}
    } @else {
      {{ data.message }}
    }
  </div>
  <div *ngIf="data.messages?.length">
    @for (message of data.messages; track message) {
      <div>
        @if (data.translateMessages) {
          {{ message | translate }}
        } @else {
          {{ message }}
        }
      </div>
    }
  </div>
  <div *ngIf="data.action" class="flex flex-row justify-start items-center">
    <button mat-raised-button (click)="data.action()">
      {{ data.actionTitle || 'Confirm' }}
    </button>
  </div>
</div>
