<hlds-dialog-drawer-close [heading]="title | translate"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex flex-col">
  <mat-form-field>
    <mat-label>{{ 'hlds.interp.table.col.label' | translate }}</mat-label>
    <input matInput [formControl]="controls.label" required />
    <mat-error *ngIf="controls.label.hasError('required')">
      {{ 'shared.error.field-is-required' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.interp.table.description-opt' | translate }}</mat-label>
    <textarea matInput [formControl]="controls.description"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button cdkFocusInitial [mat-dialog-close]="null" mat-button>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="formValue" [disabled]="!form.valid">
    {{ button | translate }}
  </button>
</mat-dialog-actions>
