import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlgorithmModelNameDescription } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { AlgorithmActionComponentData } from './algorithm-actions.component.model';

@Component({
  templateUrl: './algorithm-actions.component.html',
})
export class AlgorithmActionsComponent extends BaseFormDirective<AlgorithmModelNameDescription> implements OnInit {
  editable = false;
  titleButton = '';
  private readonly data: AlgorithmActionComponentData = inject(MAT_DIALOG_DATA);
  private readonly translate = inject(TranslateService);

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    const {
      translate,
      data: { action, row },
    } = this;
    const description = row?.description || '';
    let name = row?.name || '';

    this.titleButton = 'hlds.algorithm.action.' + action;
    this.editable = action === 'duplicate' || action === 'add';

    if (action === 'duplicate') {
      name += ' ' + translate.instant('hlds.algorithm.dupe');
    }

    this.formCreate({ description, name });
  }
}
