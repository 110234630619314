<hlds-dialog-drawer-close [heading]="'hlds.order-query.title' | translate"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col" [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ data.test.query }}</mat-label>
    <input matInput [formControl]="controls.response" required />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="formValue" [disabled]="!form.valid">
    {{ 'shared.button.save' | translate }}
  </button>
</mat-dialog-actions>
