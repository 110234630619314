import { Component, EventEmitter, inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlgorithmModel, EngineRecord, LabModel, LabsModel, OrderModel, PatientModel } from '@ci';
import { filter, forkJoin, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { routePath } from '../../app-routing/app-routing';
import { AlgorithmService } from '../../services/algorithm/algorithm.service';
import { LabService } from '../../services/lab/lab.service';
import { OrderService } from '../../services/order/order.service';
import { PatientService } from '../../services/patient/patient.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { UtilsService } from '../../services/utils/utils.service';
import { BaseSubscriptionsDirective } from '../../shared/base-subscriptions/base-subscriptions.directive';
import {
  genericTableActions,
  GenericTableOptions,
  GenericTableRun,
} from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { LabEditComponent } from './lab-edit/lab-edit.component';
import { LabEditComponentModel } from './lab-edit/lab-edit.component.model';

type OrderTab = 'labs' | 'orders' | 'patients';

@Component({
  selector: 'hlds-orders',
  templateUrl: './orders.component.html',
})
export class OrdersComponent extends BaseSubscriptionsDirective implements OnInit {
  @ViewChild('labsTemplate') labsTemplate!: TemplateRef<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  @ViewChild('ordersTemplate') ordersTemplate!: TemplateRef<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  @Output() iframeId = new EventEmitter<string>();

  labs: Required<LabsModel>[] = [];
  readonly labItems: KeyValueMenuItem[] = [
    {
      key: 'name',
      value: {
        label: 'Name',
        noTranslate: true,
        template: () => this.labsTemplate,
        type: 'template',
        unsortable: true,
      },
    },
    {
      key: 'id',
      value: {
        label: 'ID',
        type: 'id',
        noTranslate: true,
      },
    },
    {
      key: 'labs',
      value: {
        label: 'Labs',
        noTranslate: true,
        template: () => this.labsTemplate,
        type: 'template',
        unsortable: true,
      },
    },
  ];
  orders!: Required<OrderModel>[];
  readonly optionLabs: GenericTableOptions = {
    actions: [genericTableActions.edit, genericTableActions.reset],
  };
  readonly optionsOrder: GenericTableOptions = {
    enableRowClick: true,
    actions: [genericTableActions.run],
  };
  readonly orderItems: KeyValueMenuItem<keyof OrderModel | 'algorithmName' | 'patientName'>[] = [
    {
      key: 'algorithmName',
      value: {
        label: 'Algorithm Name',
        noTranslate: true,
        template: () => this.ordersTemplate,
        type: 'template',
        unsortable: true,
        color: 'link',
      },
    },
    {
      key: 'patientName',
      value: {
        label: 'Patient Name',
        noTranslate: true,
        template: () => this.ordersTemplate,
        type: 'template',
        unsortable: true,
      },
    },
    {
      key: 'orderState',
      value: {
        label: 'Status',
        noTranslate: true,
        template: () => this.ordersTemplate,
        type: 'template',
      },
    },
    {
      key: 'createdAt',
      value: { label: 'Ordered', noTranslate: true, type: 'date' },
    },
    {
      key: 'updatedAt',
      value: { label: 'Updated', noTranslate: true, type: 'date' },
    },
  ];
  readonly patientItems: KeyValueMenuItem<keyof PatientModel>[] = [
    { key: 'name', value: { label: 'Name', noTranslate: true } },
    { key: 'age', value: { label: 'Age', noTranslate: true } },
    { key: 'gender', value: { label: 'Gender', noTranslate: true } },
  ];
  patients: Readonly<PatientModel[]> = [];
  readonly tabs: OrderTab[] = ['orders', 'labs', 'patients'];

  private readonly algorithmService = inject(AlgorithmService);
  private readonly dialog = inject(MatDialog);
  private readonly labService = inject(LabService);
  private readonly orderService = inject(OrderService);
  private readonly patientService = inject(PatientService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly snackBar = inject(SnackBarService);
  private readonly utils = inject(UtilsService);
  private _tabActive!: OrderTab;

  readonly iframe = this.router.url?.includes('iframe=1');

  get tabActive(): OrderTab | undefined {
    return this._tabActive;
  }

  set tabActive(state: OrderTab) {
    if (state !== this._tabActive) {
      const { route, router, tabs, iframe } = this;

      this._tabActive = tabs.includes(state) ? state : tabs[0];

      if (!iframe) {
        router.navigate([{ tab: this._tabActive }], { relativeTo: route });
      }
    }
  }

  actionLab(run: GenericTableRun) {
    const { dialog, labService } = this;
    const row = run.row as LabsModel;
    const data: LabEditComponentModel = {
      row,
      action: run.action,
    };

    dialog
      .open(LabEditComponent, { data, width: '20rem', maxWidth: '20rem' })
      .afterClosed()
      .pipe(filter((f): f is EngineRecord => !!f))
      .subscribe((rec: EngineRecord) => {
        for (const lab of row.labs) {
          lab.value = rec[lab.varName] as number;
        }
        labService.update(row);
      });
  }

  actionOrder(run: GenericTableRun) {
    const row = run.row as Required<OrderModel>;

    if (this.iframe) {
      this.iframeId.emit(row.id);
    } else {
      if (run.action === 'run') {
        this.router.navigate([routePath.orders, row.id]);
      } else if (run.action === 'reset') {
        row.nodeNotes.length = row.nodeStates.length = 0;
        row.orderState = 'order_in_progress';
        row.orderStatus = 'order_in_progress_progress';
        row.disposition = '';

        this.orderService.update(row);
        this.snackBar.open({ message: `reset: ${row.labs.patient.name}` });
      }
    }
  }

  ngOnInit() {
    const { utils, tabs, iframe, optionsOrder } = this;
    const paths = utils.parseUrl();
    const tab = paths[0].parameters['tab'] as OrderTab;

    if (!iframe && optionsOrder.actions) {
      optionsOrder.actions.push(genericTableActions.reset);
    }

    this.tabActive = tabs.includes(tab) ? tab : tabs[0];
    this.getCollection();
  }

  rowLabs(row: LabsModel): LabModel[] {
    return row.labs;
  }

  rowOrder(row: Required<OrderModel>): OrderModel {
    return row;
  }

  private getCollection() {
    const { labService, patientService, orderService, algorithmService } = this;
    let algorithms: AlgorithmModel[] = [];
    let orders: Required<OrderModel>[] = [];

    forkJoin([
      labService.getCollection().pipe(tap((rows) => (this.labs = rows as Required<LabsModel>[]))),
      patientService.getCollection().pipe(tap((rows: PatientModel[]) => (this.patients = rows))),
      orderService.getCollection().pipe(tap((rows) => (orders = rows as Required<OrderModel>[]))),
      algorithmService.getCollection().pipe(tap((rows) => (algorithms = rows))),
    ])
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          const { labs, patients } = this;

          for (const lab of labs) {
            const patient = patients.find((p) => p.id === lab.patientId);
            if (!patient) {
              throw new Error(`No patient found!: ${lab.patientId}`);
            }

            lab.patient = patient;
          }

          for (const order of orders) {
            const lab = labs.find((l) => l.id === order.labsId);
            const algorithm = algorithms.find((a) => a.id === order.algorithmId);

            if (!lab) {
              throw new Error(`Labs not found: ${order.labsId}`);
            }

            if (!algorithm) {
              throw new Error(`Algorithm not found: ${order.algorithmId}`);
            }

            order.labs = lab;
            order.algorithm = algorithm;
          }

          this.orders = orders;
        },
        error: (e) => this.snackBar.error(e),
      });
  }
}
