<hlds-dialog-drawer-close [heading]="titleButton | translate"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex flex-col">
  <mat-form-field>
    <mat-label>{{ 'hlds.algorithms.table.col.name' | translate }}</mat-label>
    <input [formControl]="controls.name" matInput [readonly]="!editable" [required]="editable" />
    <mat-error *ngIf="controls.name.hasError('required')">
      {{ 'shared.error.field-is-required' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    @if (editable) {
      <mat-label>{{ 'hlds.algorithms.table.description-opt' | translate }}</mat-label>
    } @else {
      <mat-label>{{ 'hlds.algorithms.table.col.description' | translate }}</mat-label>
    }
    <textarea [formControl]="controls.description" matInput [readonly]="!editable"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button cdkFocusInitial [mat-dialog-close]="null" mat-button>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="formValue" [disabled]="!form.valid">
    {{ titleButton | translate }}
  </button>
</mat-dialog-actions>
