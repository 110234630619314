import { inject, Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, SnapshotAction } from '@angular/fire/compat/database';
import { AlgorithmModel, AlgorithmModelNameDescription, TreeClass } from '@ci';
import { DateTime } from 'luxon';
import { map, Observable, Subject, take } from 'rxjs';
import { EngineService } from '../engine/engine.service';
import { SettingsService } from '../settings/settings.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AlgorithmService {
  static get emptyAlgorithm(): AlgorithmModel {
    return {
      activeState: 'active',
      author: '',
      createdAt: '',
      description: '',
      draftState: 'published',
      id: '',
      interps: [],
      name: '',
      sims: [],
      tests: [],
      tree: {
        interpretation: true,
        interpMapping: {
          interpEntries: [],
        },
        nodes: [],
        serial: false,
      },
      updatedAt: '',
    };
  }

  private readonly badDate = DateTime.now().toISO() || '';
  private readonly engineService = inject(EngineService);
  private readonly fireDb = inject(AngularFireDatabase);
  private readonly fireRef: AngularFireList<AlgorithmModel> = this.fireDb.list('/algorithms');
  private readonly user = inject(UserService);
  private readonly settingsService = inject(SettingsService);

  addDuplicate(nameDescription: AlgorithmModelNameDescription, row?: AlgorithmModel): Observable<AlgorithmModel> {
    const { fireRef, user } = this;
    const result = new Subject<AlgorithmModel>();
    const model: AlgorithmModel = Object.assign({}, nameDescription) as AlgorithmModel;

    model.createdAt = model.updatedAt = DateTime.now().toISO() || '';
    model.activeState = 'active';
    model.draftState = 'draft';
    model.author = user.user.fullName;

    if (row) {
      model.tree = row.tree;
      model.tests = row.tests;
      model.interps = row.interps;
    }

    fireRef.push(model).then((resp) => {
      model.id = resp.key as string;
      this.update(model).then(() => result.next(model));
    });

    return result;
  }

  archive(model: AlgorithmModel, action: 'archive' | 'unarchive'): Promise<void> {
    model.activeState = action === 'archive' ? 'archive' : 'active';
    return this.update(model);
  }

  delete(id: string): Promise<void> {
    return this.fireRef.remove(id);
  }

  get(id: string): Observable<AlgorithmModel | undefined> {
    return this.getCollection().pipe(
      map((rows) => {
        const row = rows.find((row) => row.id === id);
        // clone it
        return row ? JSON.parse(JSON.stringify(row)) : row;
      }),
    );
  }

  getCollection(): Observable<AlgorithmModel[]> {
    const { fireRef } = this;

    return fireRef.snapshotChanges().pipe(
      map((actions: SnapshotAction<AlgorithmModel>[]) =>
        actions.map((action: SnapshotAction<AlgorithmModel>) =>
          this.#modelSafe({
            id: action.payload.key,
            ...action.payload.val(),
          } as AlgorithmModel),
        ),
      ),
      take(1),
    );
  }

  update(model: AlgorithmModel): Promise<void> {
    model.updatedAt = DateTime.now().toISO() || '';

    return this.fireRef.set(model.id, model);
  }

  /**
   * Only update the sim portion
   */
  updateSim(model: AlgorithmModel): Observable<void> {
    const result = new Subject<void>();

    this.get(model.id).subscribe((row) => {
      if (row) {
        row.sims = model.sims;
        this.fireRef.set(model.id, row).then(() => result.next());
      } else {
        result.next();
      }
    });

    return result;
  }

  #modelSafe(result: AlgorithmModel): AlgorithmModel {
    const {
      badDate,
      engineService,
      settingsService: {
        data: { algorithms },
      },
    } = this;

    result.activeState ||= 'active';
    result.draftState ||= 'draft';
    result.updatedAt ||= badDate;

    if (!Array.isArray(result.interps)) {
      result.interps = [];
    }

    if (!Array.isArray(result.sims)) {
      result.sims = [];
    }

    if (Array.isArray(result.tests)) {
      result.tests.sort();
    } else {
      result.tests = [];
    }

    if (Array.isArray(result.sims)) {
      if (result.tests.length) {
        engineService.removeUnusedProperties(result.sims, result.tests);
      } else {
        result.sims = [];
      }
    } else {
      result.sims = [];
    }

    if (typeof result.tree !== 'object') {
      result.tree = TreeClass.emptyTree;
      result.tree.interpretation = algorithms.auto.interpretation;
    } else {
      if (Array.isArray(result.tree.nodes)) {
        if (typeof result.tree.interpretation !== 'boolean') {
          result.tree.interpretation = algorithms.auto.interpretation;
        }
      } else {
        result.tree = TreeClass.emptyTree;
      }

      if (!Array.isArray(result.tree.interpMapping?.interpEntries)) {
        result.tree.interpMapping = {
          interpEntries: [],
        };
      }
    }

    TreeClass.treeSafe(result.tree.nodes);

    return result;
  }
}
