@if (isDialog) {
  <hlds-dialog-drawer-close [heading]="'hlds.algorithm-edit-test-add.title' | translate"></hlds-dialog-drawer-close>
  <mat-dialog-content class="flex-important flex-col">
    <ng-container *ngTemplateOutlet="form_"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="space-x-2">
    <button [mat-dialog-close]="null" mat-button>
      {{ 'shared.button.cancel' | translate }}
    </button>
    <button mat-flat-button [mat-dialog-close]="ids" [disabled]="ids.length === 0">
      {{ title }}
    </button>
  </mat-dialog-actions>
} @else {
  <div class="flex-important flex-col">
    <ng-container *ngTemplateOutlet="form_"></ng-container>
  </div>
}

<ng-template #form_>
  <form [formGroup]="form">
    <div class="flex flex-row justify-between items-baseline">
      <mat-form-field class="flex-1">
        <mat-label>{{ 'hlds.test.label' | translate }}</mat-label>
        <input #testNameInput matInput cdkFocusInitial [formControl]="controls.testName" [matAutocomplete]="auto" />
        @if (controls.testName.value.trim().length) {
          <mat-icon matSuffix class="cursor-pointer" (click)="controls.testName.setValue('')">{{
            'shared.icon.cancel' | translate
          }}</mat-icon>
        }
        <mat-hint *ngIf="form.valid && isDialog">
          {{ 'hlds.algorithm-edit-test-add.input-hint.dialog' | translate }}
        </mat-hint>
        <mat-error *ngIf="!controls.testName.valid">
          @if (!controls.testName.value.trim()) {
            {{ 'shared.error.field-is-required' | translate }}
          } @else {
            {{ 'hlds.algorithm-add.error.not-selected' | translate }}
          }
        </mat-error>
        <mat-autocomplete
          #auto="matAutocomplete"
          class="select-800"
          hideSingleSelectionIndicator
          (optionSelected)="controls.testName.setErrors(null)"
        >
          @if (tests().length === 0) {
            <mat-option disabled>
              {{ 'shared.table.no-data' | translate }}
            </mat-option>
          } @else {
            <mat-option disabled class="font-12 is-flex">
              <div class="grid grid-cols-[18%_37%_15%_15%_15%] smaller-font gap-2">
                <div>{{ 'hlds.test.table.col.varName' | translate }}</div>
                <div>{{ 'hlds.test.table.col.description' | translate }}</div>
                <div>{{ 'hlds.test.table.col.unit' | translate }}</div>
                <div>{{ 'hlds.test.table.col.unitType' | translate }}</div>
                <div>{{ 'hlds.test.table.col.category' | translate }}</div>
              </div>
            </mat-option>
          }
          @for (test of tests(); track test.varName) {
            <mat-option class="font-12 is-flex" [value]="test.varName">
              <div class="grid grid-cols-[18%_37%_15%_15%_15%] gap-2">
                <div class="truncate">{{ test.varName }}</div>
                <div class="truncate">{{ test.description }}</div>
                <div class="truncate">{{ test.unit }}</div>
                <div class="truncate">{{ test.unitType }}</div>
                <div class="truncate">{{ test.category }}</div>
              </div>
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      @if (isDialog) {
        <button
          class="ml-4"
          type="submit"
          mat-flat-button
          (click)="addTest()"
          [disabled]="!form.valid"
          [matTooltip]="'hlds.algorithm-edit-test-add.add-multiple-tooltip' | translate"
        >
          {{ 'hlds.algorithm-edit-test-add.add-multiple' | translate }}
        </button>
      }
    </div>
    @if (isDialog && ids.length) {
      <mat-card class="w-[50%]">
        <div class="grid grid-cols-[14%_86%] items-center">
          @for (id of ids; track $index) {
            <div>
              <button mat-icon-button (click)="removeId($index)">
                <mat-icon>{{ 'shared.icon.delete' | translate }} </mat-icon>
              </button>
            </div>
            <div>{{ id }}</div>
          }
        </div>
      </mat-card>
    }
  </form>
</ng-template>
