<hlds-dialog-drawer-close [heading]="title | translate"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col">
  <mat-form-field class="w-[20rem]">
    <mat-label>{{ 'hlds.test.table.col.varName' | translate }}</mat-label>
    <input matInput [formControl]="controls.varName" [readonly]="!!data.rec" required />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.test-custom.table.col.query' | translate }}</mat-label>
    <textarea [formControl]="controls.query" matInput [cdkTextareaAutosize]="true" required></textarea>
    <mat-hint *ngIf="controls.query.value === ''">
      {{ 'hlds.test-custom.add-edit.query-hint' | translate }}
    </mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.test.table.description-opt' | translate }}</mat-label>
    <textarea [formControl]="controls.description" matInput [cdkTextareaAutosize]="true"></textarea>
  </mat-form-field>
  <mat-form-field class="w-[20rem]">
    <mat-label>{{ 'hlds.test.table.col.category' | translate }}</mat-label>
    <input matInput [formControl]="controls.category" />
  </mat-form-field>
  <mat-form-field class="w-[20rem]">
    <mat-label>{{ 'hlds.test.table.col.unit' | translate }}</mat-label> <input matInput [formControl]="controls.unit" />
  </mat-form-field>
  <div class="flex flex-row space-x-2 items-baseline">
    <mat-label>{{ 'hlds.test.table.col.unitType' | translate }}</mat-label>
    <mat-radio-group [formControl]="controls.unitType" required>
      @for (radio of testUnitTypes; track radio) {
        <mat-radio-button [value]="radio">
          <span>{{ 'hlds.test-custom.add-edit.unitType.' + radio | translate }}</span>
        </mat-radio-button>
      }
    </mat-radio-group>
  </div>

  @if (controls.unitType.value === 'string') {
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ 'hlds.test-custom.table.col.options' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div [innerHTML]="'hlds.test-custom.add-edit.options1' | translate"></div>
        @if (options.length) {
          <div [innerHTML]="'hlds.test-custom.add-edit.options2' | translate"></div>
        }
        <mat-list>
          @for (option of options; track option) {
            <mat-list-item>
              <div matListItemTitle>{{ option }}</div>
              <mat-icon
                [matTooltip]="'hlds.test-custom.add-edit.tooltip.delete' | translate"
                matListItemIcon
                (click)="optionDelete(option)"
                >{{ 'shared.icon.delete' | translate }}</mat-icon
              >
            </mat-list-item>
          }
        </mat-list>
        <mat-form-field class="w-[20rem]">
          <mat-label>{{ 'hlds.test-custom.add-edit.option' | translate }}</mat-label>
          <input matInput [formControl]="controls.option" />
          @if (controls.option.value) {
            <mat-icon
              matSuffix
              [matTooltip]="'hlds.test-custom.add-edit.tooltip.add' | translate"
              (click)="optionAdd()"
              >{{ 'shared.icon.add' | translate }}</mat-icon
            >
          }
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button cdkFocusInitial [mat-dialog-close]="null" mat-button>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="save()" [disabled]="!form.valid">
    {{ button | translate }}
  </button>
</mat-dialog-actions>
