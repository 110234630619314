import { Location } from '@angular/common';
import { Component, ElementRef, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlgorithmModel,
  AlgorithmModelView,
  EngineValue,
  InterpModel,
  InterpRich,
  InterpRichEntry,
  NodeAny,
  NodeStop,
  TestModel,
  TestModelCount,
  TreeClass,
} from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { filter, ReplaySubject, Subject } from 'rxjs';
import { routePath } from '../../app-routing/app-routing';
import { AlgorithmService } from '../../services/algorithm/algorithm.service';
import { AppService } from '../../services/app/app.service';
import { SettingsService } from '../../services/settings/settings.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { TestService } from '../../services/test/test.service';
import { TreeService } from '../../services/tree/tree.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { GenericTableComponent } from '../../shared/generic-table/generic-table.component';
import {
  genericTableActions,
  GenericTableOptions,
  GenericTableRun,
} from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { YnComponent } from '../../shared/yn/yn.component';
import { YnComponentModel } from '../../shared/yn/yn.component.model';
import { AlgorithmTreeEditComponent } from '../algorithm-tree-edit/algorithm-tree-edit.component';
import {
  AlgorithmTreeEditComponentData,
  AlgorithmTreeEditComponentRes,
} from '../algorithm-tree-edit/algorithm-tree-edit.component.model';
import { InterpAddEditComponent } from '../interp-add-edit/interp-add-edit.component';
import { NodeEditComponent } from '../node-edit/node-edit.component';
import { NodeEditComponentData } from '../node-edit/node-edit.component.model';
import { NodeViewComponent } from '../node-view/node-view.component';
import { TestAddComponent } from '../test-add/test-add.component';
import { testItems } from './algorithm-edit.component.model';

interface InterpGrid {
  items: KeyValueMenuItem<keyof InterpModel>[];
}

interface TestGrid {
  items: KeyValueMenuItem<keyof TestModelCount>[];
  rows: TestModelCount[];
}

interface Tree {
  dirty: boolean;
  forest: boolean;
  incomplete: NodeAny[];
  status: string;
}

interface Ancestor {
  ancestors: NodeAny[];
  stop: NodeStop;
}

interface StopGrid {
  items: KeyValueMenuItem<keyof NodeStop>[];
  rows: NodeStop[];
}

interface StopMapping {
  cols: Ancestor[][];
  rootTitles: string[];
}

const enum Tabs {
  tree = 0,
  interp = 1,
}

@Component({
  templateUrl: './algorithm-edit.component.html',
  styleUrls: ['algorithm-edit.component.scss'],
})
export class AlgorithmEditComponent extends BaseFormDirective<AlgorithmModelView> implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('countTemplate') countTemplate!: TemplateRef<any>;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  @ViewChild('mappingElement') mappingElement!: ElementRef<HTMLDivElement>;

  drawerOpened = false;
  readonly editNode$ = new Subject<NodeAny>();
  readonly interpGrid: InterpGrid = {
    items: [
      { key: 'label', value: { label: 'hlds.node-edit.type.stop.label' } },
      { key: 'description', value: { label: 'hlds.node-edit.type.stop.description' } },
    ],
  };
  readonly interpRich: InterpRich = {
    richEntries: [],
  };
  readonly nodeHighlight$ = new Subject<NodeAny[] | NodeAny>();
  readOnly = false;
  readonly routePath = routePath;
  readonly row$ = new ReplaySubject<AlgorithmModel>();
  selectedIndex = 0;
  readonly simHighlight$ = new Subject<NodeAny[]>();
  readonly simValues$ = new Subject<Record<string, EngineValue> | null>();

  readonly stopGrid: StopGrid = {
    items: [
      { key: 'label', value: { label: 'hlds.node-edit.type.stop.label' } },
      { key: 'description', value: { label: 'hlds.node-edit.type.stop.description' } },
    ],
    rows: [],
  };
  readonly stopMapping: StopMapping = {
    cols: [],
    rootTitles: [],
  };
  readonly testGrid: TestGrid = {
    items: [
      ...testItems(),
      {
        key: 'count',
        value: {
          label: 'hlds.test.table.col.count',
          unsortable: true,
          align: 'center',
          template: () => this.countTemplate,
          type: 'template',
        },
      },
    ],
    rows: [],
  };
  readonly tree: Tree = {
    dirty: false,
    forest: false,
    incomplete: [],
    status: '',
  };
  readonly zoom$ = new Subject<void>();
  private readonly algorithmService = inject(AlgorithmService);
  private readonly dialog = inject(MatDialog);
  private readonly location = inject(Location);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly settingsService = inject(SettingsService);
  private readonly snackBar = inject(SnackBarService);
  private readonly testService = inject(TestService);
  private readonly translate = inject(TranslateService);
  private readonly treeService = inject(TreeService);
  private _row: AlgorithmModel = AlgorithmService.emptyAlgorithm;

  constructor() {
    super();
  }

  get row() {
    return this._row;
  }

  private set row(row: AlgorithmModel) {
    const { form } = this;
    const { description, name } = row;

    this._row = row;
    this.readOnly = row.draftState === 'published' || row.activeState === 'archive';

    TreeClass.treeAddIfEmpty(row.tree.nodes);
    form.patchValue({ name, description });
    this.#setTests();
    this.drawOpenClose();
    this.dirty = false;

    if (AppService.isLocal()) {
      this.editNode$.next(row.tree.nodes[0]);
    }
  }

  get dirty() {
    return this.tree.dirty;
  }

  set dirty(dirty: boolean) {
    const { tree, row } = this;
    const roots = TreeClass.treeRoots(row.tree.nodes);

    tree.dirty = dirty;
    tree.forest = roots.length > 1;

    if (dirty) {
      if (this.row.tests.length === 0 && this?.drawer.opened) {
        this.drawer.close();
      }

      setTimeout(() => this.drawOpenClose());
    }
  }

  get optionsStopGrid(): GenericTableOptions {
    return {
      paging: 'none',
      noFill: true,
      actions: this.readOnly ? [] : [genericTableActions.edit],
    };
  }

  get optionsInterGrid(): GenericTableOptions {
    return {
      add: !this.readOnly,
      paging: 'none',
      noFill: true,
      actions: this.readOnly ? [] : [genericTableActions.edit, genericTableActions.delete],
      disableRowClick: true,
      import: !this.readOnly,
      tooltip: {
        add: 'hlds.interp.table.add-tooltip',
        import: 'hlds.interp.table.import-tooltip',
      },
    };
  }

  get optionsTestGrid(): GenericTableOptions {
    return {
      add: !this.readOnly,
      paging: 'none',
      noFill: true,
      actions: this.readOnly ? [] : [genericTableActions.delete],
      disableRowClick: true,
      tooltip: {
        add: 'hlds.test.table.add-tooltip',
      },
    };
  }

  actionCount(test: TestModel) {
    this.#testsHighlight(test);
  }

  actionEditTree() {
    const {
      dialog,
      row: {
        tree,
        tree: { nodes },
      },
      treeService,
    } = this;
    const config: MatDialogConfig<AlgorithmTreeEditComponentData> = {
      data: {
        roots: TreeClass.treeRoots(nodes),
        tree,
      },
      width: '600px',
      maxWidth: '600px',
    };

    dialog
      .open<AlgorithmTreeEditComponent, AlgorithmTreeEditComponentData, AlgorithmTreeEditComponentRes[]>(
        AlgorithmTreeEditComponent,
        config,
      )
      .afterClosed()
      .pipe(filter((f): f is AlgorithmTreeEditComponentRes[] => !!f))
      .subscribe((rows: AlgorithmTreeEditComponentRes[]) => {
        if (rows.length) {
          treeService.nodesRootsInsert(rows, nodes);
          tree.nodes = tree.nodes.slice(); // change detection
        }

        this.dirty = true;
      });
  }

  actionInterp(run: GenericTableRun) {
    const row = run.row as InterpModel;

    switch (run.action) {
      case 'edit':
        this.#addEditInterp(row);
        break;
      case 'add':
        this.#addEditInterp();
        break;
      case 'delete':
        this.row.interps = this.row.interps.filter(
          (r) =>
            r[GenericTableComponent.idCol as keyof InterpModel] !==
            row[GenericTableComponent.idCol as keyof InterpModel],
        );
        this.dirty = true;
        break;
      case 'import':
        this.row.interps = [...this.row.interps, ...this.settingsService.data.algorithms.interps];
        this.dirty = true;
        break;
      default:
        throw new Error(`Unexpected test action: ${run.action}`);
    }
  }

  actionStop(run: GenericTableRun) {
    const row = run.row as NodeStop;
    this.#editNode(row);
  }

  actionPublish() {
    this.snackBar.open({ message: 'WIP - Push' });
  }

  actionReset() {
    const { dialog } = this;
    const data: YnComponentModel = {
      title: 'hlds.algorithm.reset.title',
      titleTranslate: true,
      message: 'hlds.algorithm.reset.message',
      messageTranslate: true,
      messageType: 'warn',
    };

    dialog
      .open(YnComponent, { data })
      .afterClosed()
      .pipe(filter((f) => !!f))
      .subscribe(() => this.#setId(this.row.id, ''));
  }

  actionSave() {
    const {
      row,
      algorithmService,
      snackBar,
      formValue: { name, description },
      translate,
    } = this;
    const message = translate.instant('hlds.algorithm.saved', { name });

    this.dirty = false;
    row.name = name;
    row.description = description;

    algorithmService.update(row).then(() => snackBar.open({ message, translateMessage: true }));
  }

  actionTabChanged(index: number) {
    this.selectedIndex = index;
    this.nodeHighlight$.next([]);

    if (index === Tabs.interp) {
      this.#mappingInit();
    }
  }

  actionTest(run: GenericTableRun) {
    const row = run.row as TestModel;

    switch (run.action) {
      case 'add':
        this.#addTest();
        break;
      case 'delete':
        this.row.tests = this.row.tests.filter((name) => name !== row.varName);
        this.#setTests();
        this.dirty = true;
        break;
      default:
        throw new Error(`Unexpected test action: ${run.action}`);
    }
  }

  drawOpenClose(userAction = false) {
    const { drawer, row$, row, location, nodeHighlight$ } = this;
    const opened = drawer?.opened ?? false;

    this.drawerOpened = opened;

    if (opened) {
      row$.next(row);
    }

    if (userAction) {
      // router.navigate reloads the screen
      nodeHighlight$.next([]);
      location.replaceState(`/${routePath.algorithms}/${row.id};sim=${drawer?.opened ? 1 : 0}`);

      if (!opened) {
        this.#simSave();
      }
    }
  }

  goBack() {
    this.router.navigate([routePath.algorithms]);
  }

  initStopGrid() {
    this.#mappingInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.#simSave();
  }

  mappingAction(action: 'add' | 'delete' | 'duplicate', cardIndex = 0) {
    const {
      row: {
        tree: {
          interpMapping: { interpEntries },
        },
      },
      mappingElement,
    } = this;

    switch (action) {
      case 'add':
        interpEntries.push({
          groups: [],
          description: '',
          label: '',
        });
        break;
      case 'delete':
        interpEntries.splice(cardIndex, cardIndex + 1);
        break;
      case 'duplicate':
        interpEntries.push(JSON.parse(JSON.stringify(interpEntries[cardIndex]))); // clone it
        break;
    }

    this.#mappingInit();
    this.tree.dirty = true;

    if ((action === 'add' || action === 'duplicate') && mappingElement) {
      setTimeout(() => mappingElement.nativeElement.scroll({ top: 9999, left: 0 }), 500);
    }
  }

  mappingHighlight(cardIndex: number, colIndex: number, showHighlight: boolean) {
    const {
      interpRich: { richEntries },
      stopMapping: { cols },
      nodeHighlight$,
    } = this;
    const richEntry = richEntries[cardIndex];
    const ancestors: Ancestor[] = cols[colIndex];
    const values = richEntry.controls.groups[colIndex].value;
    const select: string[] = [];
    const highlights: NodeAny[] = [];

    for (const id of values) {
      const match = ancestors.find((ancestor) => ancestor.stop.id === id);
      if (match) {
        highlights.push(match.stop);
        select.push(match.stop.label);
      }
    }

    richEntry.triggers[colIndex] = select.join(', ');

    if (showHighlight) {
      nodeHighlight$.next(highlights);
    }
  }

  ngOnInit() {
    const { testService, editNode$, destroyRef } = this;

    this.formCreate({
      name: '',
      description: '',
    });

    this.form.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => (this.dirty = true));

    testService
      .getCollection()
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe({
        next: () => this.route.params.subscribe((params) => this.#setId(params['id'], params['sim'])),
      });

    editNode$.pipe(takeUntilDestroyed(destroyRef)).subscribe((node) => {
      if (this.readOnly) {
        this.nodeHighlight$.next([]);
      } else if (node.id === NodeViewComponent.rootNodeId) {
        this.actionEditTree();
      } else {
        this.#editNode(node);
      }
    });
  }

  simHighlight(varName: string) {
    const test = this.testGrid.rows.find((row) => row.varName === varName);

    if (test) {
      this.#testsHighlight(test);
    } else {
      this.nodeHighlight$.next([]);
    }
  }

  #editNodeSave(data: NodeEditComponentData) {
    const { node, action } = data;
    const {
      row: {
        tree,
        tree: { nodes },
      },
    } = this;

    if (action === 'delete') {
      TreeClass.nodeDelete(nodes, node.id);
    } else {
      TreeClass.nodeSafe(node, nodes);
      setTimeout(() => {
        if (this.tree.incomplete.length === 0) {
          this.nodeHighlight$.next(node);
        }
      }, 500);
    }

    TreeClass.treeSafe(nodes);
    tree.nodes = tree.nodes.slice(); // change detection
    this.dirty = true;
  }

  #mappingInit() {
    const {
      destroyRef,
      interpRich: { richEntries },
      row: {
        tree: { nodes, interpMapping },
      },
      stopMapping: { cols, rootTitles },
      stopGrid,
      translate,
    } = this;

    const roots = TreeClass.treeRoots(nodes);
    const stops = TreeClass.treeFind<NodeStop>(nodes, 'stop');

    richEntries.length = cols.length = 0;
    rootTitles.length = roots.length;
    rootTitles.fill(translate.instant('hlds.interp-mapping.stops'));

    for (const stop of stops) {
      const ancestor: Ancestor = { stop, ancestors: TreeClass.treeAncestors(nodes, stop.id) };
      const { ancestors } = ancestor;

      if (ancestors.length === 0) {
        ancestors.push(stop); // very weird, but possible. The stop node is on top
      }

      const rootId = ancestors[0].id;
      const index = roots.findIndex((root) => root.id == rootId);

      if (index < 0) {
        throw new Error(`A stop has to have a root (even itself), stop: ${stop}, ancestors: ${ancestors}`);
      }

      if (!Array.isArray(cols[index])) {
        cols[index] = [];
      }

      cols[index].push(ancestor);
    }

    stopGrid.rows = [...cols.map((col: Ancestor[]) => col.map((ancestor) => ancestor.stop)).flat()];

    interpMapping.interpEntries.forEach((entry, cardIndex) => {
      const { groups, description, label } = entry;
      const groupControls: FormControl<number[]>[] = [];
      const groupStops: NodeStop[][] = [];
      const triggers: string[] = new Array(roots.length).fill('');
      let groups_: number[][];

      if (Array.isArray(groups) && groups.length === roots.length) {
        groups_ = groups;
      } else {
        groups_ = new Array<number[]>(roots.length).fill([]);
      }

      groups_.forEach((group, colIndex) => {
        const group_ = Array.isArray(group) ? group : [];
        const matches = group_
          .map((id) => stops.find((stop) => stop.id === id))
          .filter((stop): stop is NodeStop => !!stop);
        const control = new FormControl<number[]>(group_, { nonNullable: true });

        control.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
          this.mappingHighlight(cardIndex, colIndex, true);
          this.#mappingUpdate(cardIndex);
        });

        groupStops.push(matches);
        groupControls.push(control);
      });

      const richEntry: InterpRichEntry = {
        groups: groupStops,
        triggers,
        controls: {
          groups: groupControls,
          label: new FormControl<string>(label || '', { nonNullable: true }),
          description: new FormControl<string>(description || '', { nonNullable: true }),
        },
      };

      richEntries.push(richEntry);

      [richEntry.controls.label, richEntry.controls.description].forEach((control) =>
        control.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => this.#mappingUpdate(cardIndex)),
      );

      for (let index = 0; index < roots.length; index++) {
        this.mappingHighlight(cardIndex, index, false);
      }
    });
  }

  #mappingUpdate(cardIndex: number) {
    const {
      tree,
      interpRich: { richEntries },
      row: {
        tree: {
          interpMapping: { interpEntries },
        },
      },
    } = this;
    const {
      controls: { label, description, groups },
    } = richEntries[cardIndex];
    const interpEntry = interpEntries[cardIndex];

    interpEntry.label = label.value;
    interpEntry.description = description.value;
    interpEntry.groups = groups.map((control) => control.value || []);

    tree.dirty = true;
  }

  #setId(id: string, sim: string) {
    const { algorithmService, snackBar } = this;

    algorithmService.get(id).subscribe({
      next: (row: AlgorithmModel | undefined) => {
        if (row) {
          this.row = row;
          if (sim && +sim === 1) {
            setTimeout(() => this.drawer.open(), 500);
          }
        } else {
          this.goBack();
        }
      },
      error: (e) => snackBar.error(e),
    });
  }

  #setTests(varNames?: string[]) {
    const { testService, testGrid, row } = this;
    const tests = Array.from(new Set<string>([varNames || [], row.tests].flat()));

    testGrid.rows = tests
      .map((test) => testService.find(test))
      .filter((f): f is TestModel => !!f)
      .sort((a, b) => a.varName.localeCompare(b.varName))
      .map((r) => ({
        ...r,
        count: 0,
      }));
    row.tests = testGrid.rows.map((row) => row.varName);

    this.#treeNodesInvalid();
  }

  #simSave() {
    this.algorithmService.updateSim(this.row).subscribe();
  }

  #testsHighlight(test: TestModel) {
    const {
      row: {
        tree: { nodes },
      },
      nodeHighlight$,
    } = this;

    nodeHighlight$.next(TreeClass.treeFindWithVarName(nodes, test.varName));
  }

  #treeNodesInvalid() {
    const {
      nodeHighlight$,
      translate,
      treeService,
      row: {
        tree: { nodes },
      },
      testGrid: { rows },
      tree,
    } = this;

    treeService.nodesTestCounts(nodes, rows);
    tree.status = '';

    setTimeout(() => {
      const count = rows.filter((r) => r.count === 0).length;

      tree.status = count ? translate.instant('hlds.algorithm.error.missing-tests', { count }) : '';
      tree.incomplete = [];
      treeService.nodesInvalid(nodes, tree.incomplete);

      if (tree.incomplete.length) {
        nodeHighlight$.next(tree.incomplete);

        if (tree.status) {
          tree.status += ', ';
        }

        tree.status += translate.instant('hlds.algorithm.error.incomplete-nodes', {
          count: tree.incomplete.length,
        });
      }
    }, 500);
  }

  #addEditInterp(interp?: InterpModel) {
    const { dialog, destroyRef, row } = this;

    dialog
      .open(InterpAddEditComponent, {
        data: interp,
        width: '1000px',
        maxWidth: '1000px',
      })
      .afterClosed()
      .pipe(
        filter((f): f is InterpModel => !!f),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((rec: InterpModel) => {
        if (interp) {
          const index = row.interps.findIndex(
            (r) =>
              r[GenericTableComponent.idCol as keyof InterpModel] ===
              interp[GenericTableComponent.idCol as keyof InterpModel],
          );
          if (index >= 0) {
            row.interps[index] = rec;
          }
        } else {
          row.interps.push(rec);
        }

        row.interps = row.interps.slice();
        this.dirty = true;
      });
  }

  #addTest() {
    const {
      dialog,
      destroyRef,
      row: { tests },
    } = this;

    dialog
      .open(TestAddComponent, {
        width: '1000px',
        maxWidth: '1000px',
        data: tests,
        position: { top: '300px' },
      })
      .afterClosed()
      .pipe(
        filter((f): f is string[] => !!f),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((ids: string[]) => {
        this.dirty = true;
        this.#setTests(ids);
      });
  }

  #editNode(node: NodeAny) {
    const {
      dialog,
      testGrid: { rows },
      row: {
        tree: { nodes },
      },
    } = this;
    const data: NodeEditComponentData = {
      node,
      testRows: rows.slice(),
      nodes,
    };
    const config: MatDialogConfig<NodeEditComponentData> = {
      data,
      position: { top: '80px' },
      width: '1200px',
      maxWidth: '1200px',
    };

    dialog
      .open<NodeEditComponent, NodeEditComponentData, NodeEditComponentData>(NodeEditComponent, config)
      .afterClosed()
      .subscribe((res: NodeEditComponentData | undefined) => {
        if (res) {
          if (res.node.type === 'cond' || res.node.type == 'lab_panel') {
            this.#setTests(res.node.varNames);
          }
          this.#editNodeSave(res);
          this.#treeNodesInvalid();
        }
      });
  }
}
