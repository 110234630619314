import { inject, Injectable } from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database';
import { NodeCond, NodeCustom, NodeLabPanel, NodeShapeType, SettingsDispositionModel, TestUnitType } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, take } from 'rxjs';
import {
  SettingsAlgorithmLabel,
  SettingsAlgorithmsModel,
  SettingsI18nModel,
} from '../../models/settings-algorithms.model';
import { SettingsCcaModel } from '../../models/settings-cca.model';
import { SettingsModel } from '../../models/settings.model';

type FireModel = SettingsAlgorithmsModel | SettingsCcaModel | SettingsI18nModel;

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  static readonly blockColors: string[] = [
    '#000000de',
    '#90a4ae',
    '#d9e3f0',
    '#ffffff',
    '#e91e63',
    '#4caf50',
    '#020292',
    '#ED924E',
    '#b28ce8',
  ];
  static readonly dispositionDefaults: SettingsDispositionModel[] = [
    {
      description: 'hlds.disposition.defaults.abort.description',
      label: 'hlds.disposition.defaults.abort.label',
    },
    {
      description: 'hlds.disposition.defaults.complete.description',
      label: 'hlds.disposition.defaults.complete.label',
    },
    {
      description: 'hlds.disposition.defaults.hold.description',
      label: 'hlds.disposition.defaults.hold.label',
    },
    {
      description: 'hlds.disposition.defaults.pending-input.description',
      label: 'hlds.disposition.defaults.pending-input.label',
    },
    {
      description: 'hlds.disposition.defaults.rejection.description',
      label: 'hlds.disposition.defaults.rejection.label',
    },
  ];
  readonly data = SettingsService.empty();
  private readonly fireDb = inject(AngularFireDatabase);
  private readonly fireRef = this.fireDb.list<FireModel>('/settings');
  private readonly translate = inject(TranslateService);

  static readonly empty: () => SettingsModel = () => ({
    algorithms: {
      auto: {
        interpretation: true,
        reflex: true,
      },
      dispositions: [],
      interps: [],
      labels: {
        falseLabel: '',
        trueLabel: '',
      },
      shapes: SettingsService.shapesDefault(),
      shapesDefault: SettingsService.shapesDefault(),
      testCustoms: [],
    },
    cca: {
      enabled: false,
      pt: false,
      aptt: false,
    },
    i18n: {
      language: 'en',
      date: 'iso',
    },
  });

  static readonly shapesDefault: () => Record<NodeShapeType, NodeCustom> = () => ({
    cond: {
      fill: '#fafafa',
      outline: '#90a4ae',
    },
    comment: {
      fill: '#f0f0f0',
      outline: '#f0f0f0',
    },
    lab_panel: {
      fill: '#f0f0f0',
      outline: '#f0f0f0',
    },
    stop: {
      fill: '#fff',
      outline: '#fff',
    },
  });

  autoReflex(node: NodeCond | NodeLabPanel): boolean {
    const { reflex } = this.data.algorithms.auto;
    const { autoReflex } = node;

    return typeof autoReflex === 'boolean' ? autoReflex : reflex;
  }

  algorithmLabels(): SettingsAlgorithmLabel {
    const { translate } = this;
    const result =
      typeof this.data.algorithms.labels === 'object'
        ? this.data.algorithms.labels
        : SettingsService.empty().algorithms.labels;

    if (!result.trueLabel) {
      result.trueLabel = translate.instant('hlds.algorithm-node.true');
    }

    if (!result.falseLabel) {
      result.falseLabel = translate.instant('hlds.algorithm-node.false');
    }

    return result;
  }

  getSettings(): Observable<SettingsModel> {
    const { fireRef, data } = this;

    return fireRef.snapshotChanges().pipe(
      map((actions: SnapshotAction<FireModel>[]) =>
        actions.map((action: SnapshotAction<FireModel>) => {
          const key = action.payload.key as keyof SettingsModel;
          const model = { ...action.payload.val() } as FireModel;

          data[key] = model as unknown as any; // eslint-disable-line @typescript-eslint/no-explicit-any

          return model;
        }),
      ),
      map(() => {
        if (
          !(
            data.cca &&
            data.algorithms?.auto &&
            data.algorithms.shapes?.comment &&
            data.algorithms.shapesDefault?.comment &&
            data.i18n
          )
        ) {
          Object.assign(data, SettingsService.empty());
        }

        if (!Array.isArray(data.algorithms.testCustoms)) {
          data.algorithms.testCustoms = [];
        } else {
          for (const t of data.algorithms.testCustoms) {
            if (!Array.isArray(t.options)) {
              t.options = [];
            }

            t.category ||= '';
            t.query ||= '';
            t.unit ||= '';
            t.unitType ||= '' as TestUnitType;
          }
        }

        if (!Array.isArray(data.algorithms.dispositions) || data.algorithms.dispositions.length === 0) {
          data.algorithms.dispositions = SettingsService.dispositionDefaults.map((d) => ({
            description: this.translate.instant(d.description),
            label: this.translate.instant(d.label),
          }));
        }

        if (!Array.isArray(data.algorithms.interps)) {
          data.algorithms.interps = [];
        }

        return data;
      }),
      take(1),
    );
  }

  async update() {
    const { fireRef, data } = this;
    const algorithmKey: keyof SettingsModel = 'algorithms';
    const ccaKey: keyof SettingsModel = 'cca';
    const i18nKey: keyof SettingsModel = 'i18n';

    await fireRef.set(i18nKey, data.i18n);
    await fireRef.set(ccaKey, data.cca);
    await fireRef.set(algorithmKey, data.algorithms);
  }
}
