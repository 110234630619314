<hlds-dialog-drawer-close [heading]="'hlds.node-edit.title' | translate"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col" [formGroup]="form">
  <div class="flex row space-x-2 justify-start items-center">
    <div>{{ 'hlds.node-edit.node-type-select' | translate }}</div>
    <mat-radio-group [formControl]="controls.type">
      @for (radio of nodeTypes; track radio) {
        <mat-radio-button [value]="radio">
          <span>{{ 'hlds.node-edit.type.' + radio + '.radio' | translate }}</span>
        </mat-radio-button>
      }
    </mat-radio-group>
  </div>
  @switch (controls.type.value) {
    @case ('comment') {
      <ng-container *ngTemplateOutlet="comment_"></ng-container>
    }
    @case ('cond') {
      <hlds-node-edit-cond></hlds-node-edit-cond>
    }
    @case ('lab_panel') {
      <hlds-node-edit-cond></hlds-node-edit-cond>
    }
    @case ('stop') {
      <ng-container *ngTemplateOutlet="stop_"></ng-container>
    }
  }
  @if (controls.type.value !== 'todo') {
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'hlds.node-edit.custom.label' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="grid grid-cols-2 gap-4 items-baseline justify-items-center">
          <div>{{ 'hlds.settings.algorithms.fill-color' | translate }}</div>
          <div>{{ 'hlds.settings.algorithms.outline-color' | translate }}</div>
          <color-block
            [colors]="blockColors"
            [color]="controls.customFill.value"
            (onChangeComplete)="customChange($event, 'fill')"
          ></color-block>
          <color-block
            [colors]="blockColors"
            [color]="controls.customOutline.value"
            (onChangeComplete)="customChange($event, 'outline')"
          ></color-block>
        </div>
        <div class="mt-4 flex flex-row justify-end items-center">
          <button mat-button (click)="customReset()">
            {{ 'hlds.settings.algorithms.reset' | translate }}
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  }
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-button (click)="actionDelete()">
    {{ 'shared.button.delete' | translate }}
  </button>
  @if (!['todo', ''].includes(controls.type.value)) {
    <button
      mat-flat-button
      (click)="actionSave()"
      [disabled]="controls.type.value === 'cond' && !(controls.cFalse.value || controls.cTrue.value)"
    >
      {{ 'shared.button.save' | translate }}
    </button>
  }
</mat-dialog-actions>

<ng-template #comment_>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.comment.label' | translate }}</mat-label>
    <input [formControl]="controls.label" matInput required />
    <mat-error *ngIf="controls.label.hasError('required')">
      {{ 'shared.error.field-is-required' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.comment.description' | translate }}</mat-label>
    <textarea rows="3" [formControl]="controls.description" matInput [cdkTextareaAutosize]="true"></textarea>
  </mat-form-field>
  <mat-checkbox [formControl]="controls.noteRequired">{{
    'hlds.node-edit.type.comment.noteRequired' | translate
  }}</mat-checkbox>
</ng-template>

<ng-template #stop_>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.stop.label' | translate }}</mat-label>
    <input [formControl]="controls.label" matInput required />
    <mat-error *ngIf="controls.label.hasError('required')">
      {{ 'shared.error.field-is-required' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'hlds.node-edit.type.stop.description' | translate }}</mat-label>
    <textarea rows="3" [formControl]="controls.description" matInput [cdkTextareaAutosize]="true"></textarea>
  </mat-form-field>
</ng-template>
