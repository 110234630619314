import { Location } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { INGXLoggerMetadata, INGXLoggerMonitor, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { finalize, switchMap } from 'rxjs';
import { environment } from '../environments/environment';

import { localStorageKeys } from './local-storage-keys';
import { I18nService } from './services/i18n/i18n.service';
import { SettingsService } from './services/settings/settings.service';
import { UtilsService } from './services/utils/utils.service';

class LoggerMonitor implements INGXLoggerMonitor {
  onLog(log: INGXLoggerMetadata): void {
    // TODO b/e logging
    if (log.level >= NgxLoggerLevel.ERROR) {
      console.error(log.message); // eslint-disable-line no-console
    }
  }
}

@Component({
  selector: 'hlds-root',
  template: ` <router-outlet *ngIf="ready"></router-outlet>`,
})
export class AppComponent {
  ready = false;
  private readonly destroyRef = inject(DestroyRef);
  private readonly location = inject(Location);
  private readonly i18nService = inject(I18nService);
  private readonly iconRegistry = inject(MatIconRegistry);
  private readonly log = inject(NGXLogger);
  private readonly router = inject(Router);
  private readonly settingsService = inject(SettingsService);
  private readonly utils = inject(UtilsService);

  constructor() {
    this.init();
  }

  private init() {
    const { destroyRef, utils, log, router, location, i18nService, settingsService } = this;
    const { lastPath } = localStorageKeys;
    const path = location.path(environment.useHash) ? '' : localStorage.getItem(lastPath);

    log.registerMonitor(new LoggerMonitor());
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');

    // If this user starts up with an empty URL, see if local storage has a value
    if (path) {
      router.navigateByUrl(path);
    }

    // Store the last route
    utils.navigation(destroyRef).subscribe((ne: NavigationEnd) => localStorage.setItem(lastPath, ne.url));

    settingsService
      .getSettings()
      .pipe(
        switchMap(() => i18nService.getLanguages()),
        finalize(() => (this.ready = true)),
      )
      .subscribe({
        error: (e) => log.error(e),
      });
  }
}
