import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../environments/environment';
import { AboutComponent } from '../about/about.component';
import { AlgorithmActionsComponent } from '../algorithm/algorithm-actions/algorithm-actions.component';
import { AlgorithmEditComponent } from '../algorithm/algorithm-edit/algorithm-edit.component';
import { AlgorithmEditGuard } from '../algorithm/algorithm-edit/algorithm-edit.guard';
import { AlgorithmSimHistoryComponent } from '../algorithm/algorithm-sim-history/algorithm-sim-history.component';
import { AlgorithmSimComponent } from '../algorithm/algorithm-sim/algorithm-sim.component';
import { AlgorithmTreeEditComponent } from '../algorithm/algorithm-tree-edit/algorithm-tree-edit.component';
import { AlgorithmsComponent } from '../algorithm/algorithms/algorithms.component';
import { InterpAddEditComponent } from '../algorithm/interp-add-edit/interp-add-edit.component';
import { NodeEditComponent } from '../algorithm/node-edit/node-edit.component';
import { NodeViewComponent } from '../algorithm/node-view/node-view.component';
import { TestAddComponent } from '../algorithm/test-add/test-add.component';
import { HeaderComponent } from '../header/header.component';
import { HhComponent } from '../hh/hh.component';
import { HomeComponent } from '../home/home.component';
import { OrderActionComponent } from '../order/order-action/order-action.component';
import { OrderDetailsComponent } from '../order/order-details/order-details.component';
import { OrderDispositionComponent } from '../order/order-disposition/order-disposition.component';
import { OrderInterpComponent } from '../order/order-interp/order-interp.component';
import { OrderNoteComponent } from '../order/order-note/order-note.component';
import { OrderQueryComponent } from '../order/order-query/order-query.component';
import { OrderViewComponent } from '../order/order-view/order-view.component';
import { LabEditComponent } from '../order/orders/lab-edit/lab-edit.component';
import { OrdersComponent } from '../order/orders/orders.component';
import { RootComponent } from '../root/root.component';
import { DispositionAddEditComponent } from '../settings/disposition-add-edit/disposition-add-edit.component';
import { SettingsAlgorithmsComponent } from '../settings/settings-algorithms/settings-algorithms.component';
import { SettingsCcaComponent } from '../settings/settings-cca/settings-cca.component';
import { SettingsI18nComponent } from '../settings/settings-i18n/settings-i18n.component';
import { SettingsComponent } from '../settings/settings.component';
import { TestCustomAddEditComponent } from '../settings/test-custom-add-edit/test-custom-add-edit.component';
import { SharedModule } from '../shared/shared.module';
import { routePath } from './app-routing';
import { NodeEditCondComponent } from '../algorithm/node-edit-cond/node-edit-cond.component';

const components = [
  AboutComponent,
  AlgorithmActionsComponent,
  AlgorithmEditComponent,
  AlgorithmsComponent,
  AlgorithmSimComponent,
  AlgorithmSimHistoryComponent,
  AlgorithmTreeEditComponent,
  DispositionAddEditComponent,
  NodeViewComponent,
  HeaderComponent,
  HhComponent,
  HomeComponent,
  InterpAddEditComponent,
  LabEditComponent,
  NodeEditComponent,
  OrderActionComponent,
  OrderDetailsComponent,
  OrderDispositionComponent,
  OrderInterpComponent,
  OrdersComponent,
  OrderNoteComponent,
  OrderQueryComponent,
  OrderViewComponent,
  RootComponent,
  SettingsAlgorithmsComponent,
  SettingsCcaComponent,
  SettingsComponent,
  SettingsI18nComponent,
  TestAddComponent,
  TestCustomAddEditComponent,
];

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    children: [
      {
        canDeactivate: [(component: AlgorithmEditComponent) => inject(AlgorithmEditGuard).canDeactivate(component)],
        path: `${routePath.algorithms}/:id`,
        component: AlgorithmEditComponent,
      },
      {
        path: routePath.algorithms,
        component: AlgorithmsComponent,
      },
      {
        path: routePath.settings,
        component: SettingsComponent,
      },
      {
        path: routePath.home,
        component: HomeComponent,
      },
      {
        path: `${routePath.orders}/:id`,
        component: OrderViewComponent,
      },
      {
        path: routePath.orders,
        component: OrdersComponent,
      },
      {
        path: 'hh',
        component: HhComponent,
      },
      {
        path: '**',
        redirectTo: routePath.home,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: environment.useHash }), SharedModule],
  exports: [RouterModule],
  declarations: [...components, NodeEditCondComponent],
})
export class AppRoutingModule {}
