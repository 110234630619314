import { Component, inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EngineNode, TreeClass } from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { GenericTableOptions } from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { OrderDetailsComponentModel } from './order-details.component.model';

interface GridModel {
  label: string;
  evaluates: string;
  values: string[];
}
@Component({
  templateUrl: './order-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './order-details.component.scss',
})
export class OrderDetailsComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('valuesTemplate') valuesTemplate!: TemplateRef<any>;
  readonly items: KeyValueMenuItem<keyof GridModel>[] = [
    {
      key: 'label',
      value: { label: 'hlds.node-edit.type.stop.ancestors.label', unsortable: true },
    },
    {
      key: 'values',
      value: {
        label: 'hlds.node-edit.type.stop.ancestors.values',
        unsortable: true,
        template: () => this.valuesTemplate,
        type: 'template',
      },
    },
    {
      key: 'evaluates',
      value: { label: 'hlds.node-edit.type.stop.ancestors.evaluates', unsortable: true },
    },
  ];
  readonly options: GenericTableOptions = {
    paging: 'none',
    noFill: true,
  };
  readonly gridModelRows: GridModel[][] = [];
  private readonly data: OrderDetailsComponentModel = inject(MAT_DIALOG_DATA);
  private readonly translate = inject(TranslateService);

  ngOnInit() {
    const {
      gridModelRows,
      data: { nodes, engineRun },
      translate,
    } = this;
    const stopStr = translate.instant('hlds.order-details.stop');
    const incomplete = translate.instant('hlds.order-details.incomplete');

    for (const node of [...engineRun.orders, ...engineRun.stops]) {
      const ancestors = TreeClass.treeAncestors(nodes, node, true) as EngineNode[];
      const rows: GridModel[] = [];

      for (const node of ancestors) {
        let evaluates = '';
        let label = node.label;
        const values: string[] = [];

        switch (node.type) {
          case 'cond':
            {
              const { condIsTrue, varNames, testValues } = node;

              label = node.cond;

              if (testValues && typeof condIsTrue === 'boolean') {
                if (testValues.includes('')) {
                  evaluates = incomplete;
                } else {
                  evaluates = translate.instant('hlds.algorithm-node.' + (condIsTrue ? 'true' : 'false'));
                }
                varNames.forEach((id, index) => {
                  const value = testValues[index];

                  if (value) {
                    values.push(`${id} = ${value}`);
                  }
                });

                if (values.length === 0) {
                  values.push(incomplete);
                }
              }
            }
            break;
          case 'lab_panel':
            {
              const { varNames, testValues } = node;

              if (testValues) {
                varNames.forEach((id, index) => {
                  const value = testValues[index];

                  if (value) {
                    values.push(`${id} = ${value}`);
                  }
                });
              }

              if (values.length === 0) {
                values.push(incomplete);
              }
            }
            break;
          case 'stop':
            values.push(stopStr);
            break;
        }

        rows.push({
          evaluates,
          label,
          values,
        });
      }

      if (rows.length) {
        gridModelRows.push(rows);
      }
    }
  }
}
