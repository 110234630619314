import { Component, inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlgorithmModel,
  EngineNode,
  EngineNodeCond,
  EngineValue,
  LabModel,
  LabsModel,
  NodeAny,
  NodeCond,
  nodeTypes,
  OrderAction,
  OrderModel,
  OrderNodeNote,
  OrderNodeStateModel,
  OrderNodeStatus,
  PatientModel,
  TestCustomModel,
  TreeClass,
} from '@ci';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { filter, forkJoin, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { routePath } from '../../app-routing/app-routing';
import { LocalStorageKey } from '../../local-storage-keys';
import { AlgorithmService } from '../../services/algorithm/algorithm.service';
import { EngineService } from '../../services/engine/engine.service';
import { LabService } from '../../services/lab/lab.service';
import { OrderService } from '../../services/order/order.service';
import { PatientService } from '../../services/patient/patient.service';
import { SettingsService } from '../../services/settings/settings.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { StoreService } from '../../services/store/store.service';
import { TestService } from '../../services/test/test.service';
import { BaseSubscriptionsDirective } from '../../shared/base-subscriptions/base-subscriptions.directive';
import { GenericTableAction, GenericTableOptions } from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { OrderActionComponent } from '../order-action/order-action.component';
import { OrderActionComponentModel } from '../order-action/order-action.component.model';
import { OrderDispositionComponent } from '../order-disposition/order-disposition.component';
import {
  OrderDispositionComponentModel,
  OrderDispositionComponentRes,
} from '../order-disposition/order-disposition.component.model';
import { OrderInterpComponent } from '../order-interp/order-interp.component';
import { OrderInterpComponentModel } from '../order-interp/order-interp.component.model';
import { OrderNoteComponent } from '../order-note/order-note.component';
import { OrderNoteComponentModel } from '../order-note/order-note.component.model';
import { OrderQueryComponent } from '../order-query/order-query.component';
import { OrderQueryComponentModel, OrderQueryComponentRes } from '../order-query/order-query.component.model';

type StoreType = 'selectedIndex';

@Component({
  templateUrl: './order-view.component.html',
  styleUrl: '/order-view.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class OrderViewComponent extends BaseSubscriptionsDirective implements OnInit {
  @ViewChild('labsTemplate') labsTemplate!: TemplateRef<LabModel>;
  @ViewChild('nodeStatesTemplate') nodeStatesTemplate!: TemplateRef<OrderNodeStateModel>;
  @ViewChild('orderTemplate') orderTemplate!: TemplateRef<EngineNode>;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;

  algorithm!: AlgorithmModel;
  done: '' | 'done-node' | 'done-order' = '';
  formValue!: Record<string, EngineValue>;
  readonly nodeHighlight$ = new Subject<NodeAny[] | NodeAny>();
  nodeStateRow!: OrderNodeStateModel;
  nodeStateItems: KeyValueMenuItem<keyof OrderNodeStateModel>[] = [
    {
      key: 'nodeLabel',
      value: {
        label: 'hlds.node-edit.type.stop.ancestors.label',
      },
    },
    {
      key: 'nodeState',
      value: {
        label: 'hlds.order-action.table.col.nodeState',
        template: () => this.nodeStatesTemplate,
        unsortable: true,
        type: 'template',
      },
    },
    {
      key: 'createdAt',
      value: {
        label: 'hlds.order-action.table.col.createdAt',
        type: 'date',
      },
    },
  ];
  nodeStateOptions: GenericTableOptions = {
    paging: 'none',
    noFill: true,
    getCss: (row: OrderNodeStateModel, item?: KeyValueMenuItem) =>
      item ? '' : this.lastOrderStates.includes(row) ? 'bold-row' : '',
  };
  labsModel!: LabsModel;
  readonly labItems: KeyValueMenuItem<keyof LabModel | 'actions'>[] = [
    { key: 'varName', value: { label: 'hlds.lab.table.col.varName' } },
    { key: 'value', value: { label: 'hlds.lab.table.col.value', align: 'right' } },
    { key: 'createdAt', value: { label: 'hlds.lab.table.col.createdAt', type: 'date' } },
    { key: 'updatedAt', value: { label: 'hlds.lab.table.col.updatedAt', type: 'date' } },
    {
      key: 'actions',
      value: {
        label: 'shared.table.col.action',
        template: () => this.labsTemplate,
        type: 'template',
        unsortable: true,
      },
    },
  ];
  lastOrderStates!: OrderNodeStateModel[];
  lastOrderState!: OrderNodeStateModel;
  lastRows: EngineNode[] = [];
  nodeEngine!: EngineNode;
  readonly numTabs = 4;
  readonly options: GenericTableOptions = {
    paging: 'none',
    noFill: true,
  };
  order!: OrderModel;
  patient!: PatientModel;
  readonly routePath = routePath;
  selectedIndex = 0;
  stats: EngineNode[] = [];
  readonly storeRecord: Record<StoreType, number | string> = {
    selectedIndex: 0,
  };
  status = '';
  readonly simHighlight$ = new Subject<NodeAny[]>();
  readonly simValues$ = new Subject<Record<string, EngineValue> | null>();

  treeAncestorItems: KeyValueMenuItem<keyof NodeAny | keyof NodeCond | 'action-labs' | 'icon'>[] = [
    {
      key: 'icon',
      value: {
        label: '',
        template: () => this.orderTemplate,
        type: 'template',
        noTranslate: true,
        unsortable: true,
      },
    },
    {
      key: 'label',
      value: {
        label: 'hlds.node-edit.type.stop.ancestors.label',
        template: () => this.orderTemplate,
        type: 'template',
        unsortable: true,
      },
    },
    {
      key: 'testValues',
      value: {
        label: 'hlds.lab.table.col.value',
        template: () => this.orderTemplate,
        type: 'template',
        unsortable: true,
      },
    },
    {
      key: 'condIsTrue',
      value: {
        label: 'hlds.node-edit.type.stop.ancestors.evaluates',
        template: () => this.orderTemplate,
        type: 'template',
        unsortable: true,
      },
    },
    {
      key: 'action-labs',
      value: {
        label: 'shared.table.col.action',
        template: () => this.orderTemplate,
        type: 'template',
        unsortable: true,
      },
    },
  ];
  treeAncestorNodes: EngineNode[][] = [];

  private readonly algorithmService = inject(AlgorithmService);
  private readonly engineService = inject(EngineService);
  private readonly dialog = inject(MatDialog);
  private readonly labService = inject(LabService);
  private readonly orderService = inject(OrderService);
  private readonly patientService = inject(PatientService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  readonly iframe = this.router.url.includes('iframe=1');
  private readonly settingsService = inject(SettingsService);
  private readonly snackBar = inject(SnackBarService);
  private readonly storeKey: LocalStorageKey = 'order';
  private readonly storeService = inject(StoreService);
  private readonly testService = inject(TestService);
  private readonly translate = inject(TranslateService);

  actionLab(action: GenericTableAction, row: LabModel) {
    this.snackBar.open({
      message: `TODO: API ${action}, var: ${row.varName}, value: ${row.value}   ${row.createdAt} ${row.updatedAt}`,
    });
  }

  actionOrder(action: OrderAction, row: EngineNode) {
    const { dialog, order, orderService } = this;
    const data: OrderActionComponentModel = {
      action,
      order,
      row,
    };

    dialog
      .open(OrderActionComponent, { data, width: '800px', maxWidth: '800px' })
      .afterClosed()
      .pipe(filter((f): f is boolean => !!f))
      .subscribe(() => {
        const { nodeStates } = order;
        let nodeStatus: OrderNodeStatus;

        switch (action) {
          case 'cancel':
            nodeStatus = 'node_cancelled_by_user';
            break;
          case 'pause':
            nodeStatus = 'node_paused_by_user';
            break;
          case 'continue':
            if (row.type === 'comment') {
              nodeStatus = 'node_active_comment_approved';
            } else {
              nodeStatus = 'node_active_running';
            }
            break;
        }

        nodeStates.push({
          nodeId: row.id,
          nodeState: orderService.actionToNodeState(action),
          nodeStatus,
          createdAt: DateTime.now().toISO(),
        });

        this.updateOrder();
      });
  }

  actionDisposition(action: OrderAction) {
    const { dialog, order } = this;
    const data: OrderDispositionComponentModel = {
      action,
    };

    dialog
      .open(OrderDispositionComponent, { data, width: '800px', maxWidth: '800px' })
      .afterClosed()
      .pipe(filter((f): f is OrderDispositionComponentRes => !!f))
      .subscribe((res: OrderDispositionComponentRes) => {
        order.disposition = res.disposition;
        order.orderState = 'order_done';
        order.orderStatus = 'order_done_cancelled';
        this.updateOrder();
      });
  }

  actionInterp() {
    const { dialog, order, labsModel, algorithm } = this;
    const data: OrderInterpComponentModel = {
      algorithm,
      labsModel,
      order,
    };

    dialog
      .open(OrderInterpComponent, { width: '1000px', maxWidth: '800px', data })
      .afterClosed()
      .pipe(filter((f) => !!f))
      .subscribe({});
  }

  actionNote(node: EngineNode) {
    const {
      dialog,
      order,
      order: { nodeNotes },
    } = this;
    const row = nodeNotes.find((note) => note.nodeId === node.id);
    const data: OrderNoteComponentModel = {
      row,
      node,
    };
    dialog
      .open(OrderNoteComponent, { data, width: '800px', maxWidth: '800px' })
      .afterClosed()
      .pipe(filter((f): f is OrderNodeNote => !!f))
      .subscribe((res: OrderNodeNote) => {
        if (row) {
          row.note = res.note;
          row.updatedAt = DateTime.now().toISO();
        } else {
          res.updatedAt = res.createdAt = DateTime.now().toISO();
          res.nodeId = node.id;
          order.nodeNotes.push(res);
        }
        this.updateOrder();
      });
  }

  actionQuery(node: EngineNodeCond) {
    const test = this.queryRequired(node);

    if (!test) {
      return;
    }

    const {
      dialog,
      labsModel,
      labsModel: { labs },
      labService,
    } = this;
    const data: OrderQueryComponentModel = {
      node,
      test,
    };

    dialog
      .open(OrderQueryComponent, { data, width: '600px', maxWidth: '600px' })
      .afterClosed()
      .pipe(filter((f): f is OrderQueryComponentRes => !!f))
      .subscribe((res: OrderQueryComponentRes) => {
        const lab = labs.find((lab) => lab.varName == test.varName);
        const date = DateTime.now().toISO();

        if (lab) {
          lab.value = res.response;
          lab.updatedAt = date;
        } else {
          labs.push({
            value: res.response,
            varName: test.varName,
            createdAt: date,
            updatedAt: date,
          });
        }

        labService.update(labsModel);
        this.treeTest();
      });
  }

  /**
   * @param node NodeEngine
   * @return true if the node does *NOT* need user interaction
   */
  autoReflex(node: EngineNode): boolean {
    if (!(node.type === 'cond' || node.type === 'comment' || node.type === 'lab_panel')) {
      return true;
    }

    const {
      order: { nodeNotes, nodeStates },
      settingsService,
      engineService,
    } = this;
    let result = !!nodeNotes.find((n) => n.nodeId === node.id);

    if (!result) {
      const match = nodeStates.find((n) => n.nodeId === node.id);
      if (match) {
        result = match.nodeState === 'node_active';
      }
    }

    if (!result) {
      if (node.type === 'cond' || node.type === 'lab_panel') {
        result = engineService.testValuesComplete(node) || settingsService.autoReflex(node);
      } else {
        result = !node.noteRequired;
      }
    }

    return result;
  }

  highlight(varName: string) {
    const {
      algorithm: { tree },
      nodeHighlight$,
    } = this;
    nodeHighlight$.next(varName ? TreeClass.treeFindWithVarName(tree.nodes, varName) : []);
  }

  isOrderNode(): boolean {
    const find = this.lastOrderStates.find((r) => r.nodeId === this.nodeEngine.id);

    if (find) {
      this.lastOrderState = find;
    }

    return !!find;
  }

  ngOnInit() {
    const { route, storeService, storeKey, storeRecord, numTabs } = this;
    const rec = storeService.getSafeObject<Record<StoreType, number | string>>(storeKey, storeRecord);

    this.selectedIndex = Math.min(Math.max(0, (rec.selectedIndex as number) ?? 0), numTabs - 1);
    route.params.subscribe((params) => this.setId(params['id']));
  }

  noteFound(node: EngineNode): boolean {
    return !!this.order.nodeNotes.find((n) => n.nodeId === node.id);
  }

  queryRequired(node: EngineNode): TestCustomModel | undefined {
    const { engineService, testService, lastRows } = this;

    if (
      (node.type === 'cond' || node.type === 'lab_panel') &&
      lastRows.includes(node) &&
      !engineService.testValuesComplete(node)
    ) {
      for (let ia = 0; ia < node.testValues.length; ++ia) {
        if (!engineService.isValue(node.testValues[ia])) {
          const test = testService.find(node.varNames[ia]);

          if (test && (test as TestCustomModel).query) {
            return test as TestCustomModel;
          }
        }
      }
    }

    return undefined;
  }

  safeNode(node: EngineNode): boolean {
    const result = nodeTypes.includes(node?.type);

    if (result) {
      this.nodeEngine = node;
    }

    return result;
  }

  safeOrderNodeState(node: OrderNodeStateModel): boolean {
    const result = !!(node?.nodeState && node.nodeLabel);

    if (result) {
      this.nodeStateRow = node;
    }

    return result;
  }

  private initTabs() {
    setTimeout(() => {
      const { tabGroup, destroyRef, storeRecord, storeService, storeKey } = this;

      tabGroup.selectedTabChange.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
        storeRecord.selectedIndex = tabGroup.selectedIndex ?? 0;
        storeService.setObject(storeKey, storeRecord);
      });
    }, 100);
  }

  private setId(id: string) {
    const { algorithmService, engineService, labService, orderService, patientService, router, testService } = this;
    let algorithms: AlgorithmModel[] = [];
    let orders: OrderModel[] = [];
    let labs: LabsModel[] = [];
    let patients: PatientModel[] = [];

    forkJoin([
      labService.getCollection().pipe(tap((rows) => (labs = rows))),
      patientService.getCollection().pipe(tap((rows: PatientModel[]) => (patients = rows))),
      orderService.getCollection().pipe(tap((rows) => (orders = rows))),
      algorithmService.getCollection().pipe(tap((rows) => (algorithms = rows))),
      testService.getCollection(),
    ]).subscribe(() => {
      const order = orders.find((o) => o.id === id);

      if (order) {
        const algorithm = algorithms.find((o) => o.id === order.algorithmId);

        if (algorithm) {
          const lab = labs.find((lab) => lab.id === order.labsId);

          if (lab) {
            const patient = patients.find((patient) => patient.id === lab.patientId);

            if (patient) {
              const varNames = TreeClass.treeSortTests(algorithm.tree.nodes); // TODO gojs
              const labSorted: LabModel[] = [];

              for (const varName of varNames) {
                const oneLab = lab.labs.find((l) => l.varName == varName);

                if (oneLab) {
                  if (!engineService.isValue(oneLab.value)) {
                    oneLab.updatedAt = '';
                  }
                  labSorted.push(oneLab);
                }
              }

              if (labSorted.length === lab.labs.length) {
                lab.labs = labSorted;
              }

              this.algorithm = algorithm;
              this.labsModel = lab;
              this.patient = patient;
              this.order = order;
              this.treeTest();
              this.initTabs();
            }
          }
        }
      }

      if (!this.order) {
        router.navigate([routePath.orders]);
      }
    });
  }

  private setProcessState() {
    // Regenerate the nodes each time: see splice below
    // TODO gojs this.stats.map((node) => TreeClass.treeTrace(this.algorithm.tree.nodes, [node], true),
    this.treeAncestorNodes = [];

    const { order, algorithm, treeAncestorNodes } = this;
    const lastRows: EngineNode[] = [];

    for (const ancestorNodes of treeAncestorNodes) {
      let last = ancestorNodes[ancestorNodes.length - 1];
      const index = ancestorNodes.findIndex((node) => !this.autoReflex(node));

      if (index > -1) {
        last = ancestorNodes[index];
        ancestorNodes.splice(index + 1, ancestorNodes.length - index);
      }

      lastRows.push(last);
    }

    this.lastRows = lastRows;
    this.lastOrderStates = lastRows
      .map((node) => order.nodeStates.find((nodeState) => nodeState.nodeId === node.id))
      .filter((r): r is OrderNodeStateModel => !!r);

    for (const row of order.nodeStates) {
      const node = TreeClass.nodeFind(algorithm.tree.nodes, row.nodeId);

      if (node) {
        row.nodeLabel = node.type === 'cond' ? node.cond : node.label;
      }
    }

    const { translate, lastOrderStates } = this;
    const partial = lastRows.some((stat) => stat.type === 'stop');
    const paused = lastRows.some((stat) => {
      let result = false;

      switch (stat.type) {
        case 'lab_panel':
        case 'cond':
          result = !this.autoReflex(stat);
          break;
        case 'comment':
          result = stat.noteRequired;
          break;
      }

      const lastOrder = lastOrderStates.find((l) => l.nodeId === stat.id);

      if (lastOrder) {
        switch (lastOrder.nodeState) {
          case 'node_paused':
            result = true;
            break;
          case 'node_cancelled':
          case 'node_active':
          case 'node_done':
            result = false;
        }
      }

      return result;
    });

    if (order.orderState === 'order_done') {
      this.done = 'done-order';
      this.status = translate.instant('hlds.order.state.' + order.orderStatus);
    } else if (lastRows.every((stat) => stat.type === 'stop')) {
      this.done = 'done-node';
      this.status = translate.instant('hlds.order.state.order_done_wait_interp');
    } else {
      this.done = '';

      if (partial) {
        this.status = translate.instant('hlds.order.state.order_in_progress_partial');
      } else {
        this.status = translate.instant('hlds.order.state.order_in_progress');
      }

      if (paused) {
        this.status = translate.instant('hlds.order.state.order_paused');
      }
    }
  }

  private treeTest() {
    const {
      algorithm: {
        tests,
        tree: { nodes }, // TODO gojs
      },
      engineService,
      labsModel: { labs },
      simHighlight$,
      simValues$,
    } = this;
    const values: Record<string, EngineValue> = {};

    for (const lab of labs) {
      values[lab.varName] = lab.value;
    }

    this.formValue = values;
    simValues$.next(values);

    this.stats = engineService.run(nodes, values, tests).engineNodes;
    this.setProcessState();
    simHighlight$.next(this.stats);
  }

  private updateOrder() {
    const { order, orderService } = this;

    order.nodeStates = order.nodeStates.slice(); // force change detection
    orderService.update(order);
    this.setProcessState();
  }
}
