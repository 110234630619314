import { Component, inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlgorithmActiveState, algorithmActiveStates, AlgorithmModel, AlgorithmModelNameDescription } from '@ci';
import { filter } from 'rxjs';
import { routePath } from '../../app-routing/app-routing';
import { AlgorithmService } from '../../services/algorithm/algorithm.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { UtilsService } from '../../services/utils/utils.service';
import { BaseSubscriptionsDirective } from '../../shared/base-subscriptions/base-subscriptions.directive';
import { BaseTypeAction } from '../../shared/base-table/base-table.model';
import {
  GenericTableAction,
  genericTableActions,
  GenericTableOptions,
  GenericTableRun,
} from '../../shared/generic-table/generic-table.model';
import { KeyValueMenuItem } from '../../shared/menu-item';
import { AlgorithmActionsComponent } from '../algorithm-actions/algorithm-actions.component';
import { AlgorithmActionComponentData } from '../algorithm-actions/algorithm-actions.component.model';

interface Row extends AlgorithmModel {
  actions: BaseTypeAction<GenericTableAction>[]; // don't call this column 'action' (reserved)
}

@Component({
  templateUrl: './algorithms.component.html',
  styleUrls: ['./algorithms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlgorithmsComponent extends BaseSubscriptionsDirective implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('actionTemplate') actionTemplate!: TemplateRef<any>;

  readonly items: KeyValueMenuItem<keyof Row>[] = [
    { key: 'id', value: { type: 'id', hidden: true } },
    {
      key: 'name',
      value: { label: 'hlds.algorithms.table.col.name', color: 'link' },
    },
    {
      key: 'description',
      value: { label: 'hlds.algorithms.table.col.description' },
    },
    {
      key: 'draftState',
      value: { label: 'hlds.algorithms.table.col.draftState', type: 'titleCase' },
    },
    {
      key: 'updatedAt',
      value: { label: 'hlds.algorithms.table.col.updatedAt', type: 'dateOnly' },
    },
    { key: 'author', value: { label: 'hlds.algorithms.table.col.author' } },
    {
      key: 'actions',
      value: {
        label: 'shared.table.col.action',
        unsortable: true,
        template: () => this.actionTemplate,
        type: 'template',
      },
    },
  ];
  options: GenericTableOptions = {
    paging: 'none',
    enableRowClick: true,
    noFill: true,
    tooltip: { add: 'hlds.algorithms.table.add-tooltip' },
  };
  rows!: Row[];
  readonly tabs: KeyValueMenuItem<AlgorithmActiveState>[] = [
    { key: 'active', value: { label: 'hlds.algorithms.tab.active' } },
    { key: 'archive', value: { label: 'hlds.algorithms.tab.archive' } },
  ];
  private readonly algorithmService = inject(AlgorithmService);
  private allRows!: Row[];
  private readonly dialog = inject(MatDialog);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly snackBar = inject(SnackBarService);
  private readonly utils = inject(UtilsService);
  private _tabActive!: AlgorithmActiveState;

  get tabActive(): AlgorithmActiveState | undefined {
    return this._tabActive;
  }

  set tabActive(state: AlgorithmActiveState) {
    if (state !== this._tabActive) {
      const { route, router } = this;

      this._tabActive = algorithmActiveStates.includes(state) ? state : algorithmActiveStates[0];
      router.navigate([{ state: this._tabActive }], { relativeTo: route, replaceUrl: true });
      this.#setRows();
    }
  }

  action(run: GenericTableRun) {
    const row = run.row as AlgorithmModel;

    switch (run.action) {
      // run/edit/view have the same functionality
      case 'run':
      case 'edit':
      case 'view':
        this.router.navigate([routePath.algorithms, row.id]);
        break;
      case 'add':
      case 'delete':
      case 'archive':
      case 'duplicate':
      case 'unarchive':
        this.#action(run.action, row);
        break;
      default:
        this.snackBar.open({ message: 'Unexpected: ' + run.action + ' ' + row?.name });
        throw new Error(`Unexpected action: ${run.action}`);
    }
  }

  ngOnInit() {
    const { utils } = this;
    const paths = utils.parseUrl();
    let state = paths[0].parameters['state'] as AlgorithmActiveState;

    state = algorithmActiveStates.includes(state) ? state : algorithmActiveStates[0];
    this.tabActive = state;

    this.#getCollection();
  }

  #action(action: 'add' | 'archive' | 'duplicate' | 'unarchive' | 'delete', row?: AlgorithmModel) {
    const { dialog, destroyRef } = this;
    const data: AlgorithmActionComponentData = {
      action,
      row,
    };

    dialog
      .open(AlgorithmActionsComponent, { width: '800px', maxWidth: '800px', data })
      .afterClosed()
      .pipe(
        filter((f): f is AlgorithmModelNameDescription => !!f),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe((nameDescription: AlgorithmModelNameDescription) => {
        switch (action) {
          case 'add':
            this.#addDuplicate(nameDescription);
            break;
          case 'archive':
          case 'unarchive':
            this.#archive(action, row as AlgorithmModel);
            break;
          case 'delete':
            this.#delete(row as AlgorithmModel);
            break;
          case 'duplicate':
            this.#addDuplicate(nameDescription, row);
            break;
          default:
            throw new Error(`Unexpected: ${action}`);
        }
      });
  }

  #addDuplicate(nameDescription: AlgorithmModelNameDescription, row?: AlgorithmModel) {
    const { algorithmService, router } = this;

    algorithmService.addDuplicate(nameDescription, row).subscribe({
      next: (r) => {
        if (row) {
          this.#getCollection();
          if (this.tabActive === 'archive') {
            this.tabActive = 'active';
          }
        } else {
          router.navigate([routePath.algorithms, r.id]);
        }
      },
      error: (e) => this.snackBar.error(e),
    });
  }

  #archive(action: 'archive' | 'unarchive', row: AlgorithmModel) {
    const { algorithmService } = this;

    algorithmService.archive(row, action).then(() => this.#getCollection());
  }

  #delete(row: AlgorithmModel) {
    const { algorithmService } = this;

    algorithmService.delete(row.id).then(() => this.#getCollection());
  }

  #getCollection() {
    const { algorithmService, snackBar } = this;
    const { archive, unarchive, delete: del, duplicate, view, edit } = genericTableActions;

    algorithmService.getCollection().subscribe({
      next: (rows: AlgorithmModel[]) => {
        this.allRows = rows
          .map((r: AlgorithmModel) => {
            const row = r as Row;
            const isDraft = row.draftState === 'draft';

            if (row.activeState === 'active') {
              row.actions = isDraft ? [edit, duplicate, archive, del] : [view, duplicate, archive];
            } else {
              row.actions = [view, duplicate, unarchive];
            }

            return row;
          })
          .sort((a, b) => a.draftState.localeCompare(b.draftState) || b.updatedAt.localeCompare(a.updatedAt));
        this.#setRows();
      },
      error: (e) => snackBar.error(e),
    });
  }

  #setRows() {
    const { allRows, tabActive } = this;
    const isActive = tabActive === 'active';

    if (allRows) {
      this.rows = allRows.filter((row) => row.activeState === tabActive);
      this.options = {
        add: isActive,
        enableRowClick: true,
        noFill: true,
      };
    }
  }
}
