<hlds-dialog-drawer-close [heading]="'hlds.algorithm-tree-edit.title' | translate"></hlds-dialog-drawer-close>
<mat-dialog-content>
  <div class="grid grid-cols-[50%_50%] gap-4 items-center mb-4">
    <div>{{ 'hlds.algorithm-tree-edit.col.tree' | translate }}</div>
    <div>{{ 'hlds.algorithm-tree-edit.col.sibling' | translate }}</div>
    @for (node of data.roots; track idx; let idx = $index) {
      <div class="h-[100px]">
        <hlds-node-view [nodes]="[node]" [traverse]="false"></hlds-node-view>
      </div>

      <div class="flex flex-row">
        <mat-radio-group [(ngModel)]="radios[idx]" (click)="countRadios()">
          @for (lr of lrs; track lr) {
            <mat-radio-button [value]="lr">{{ 'hlds.algorithm-tree-edit.lr.' + lr | translate }}</mat-radio-button>
          }
        </mat-radio-group>
      </div>
    }
  </div>
  <div class="flex flex-col">
    <div>{{ 'hlds.settings.algorithms.interpretation-heading' | translate }}</div>
    <mat-checkbox [formControl]="interpretation"
      >{{ 'hlds.settings.algorithms.interpretation' | translate }}
    </mat-checkbox>
    @if (data.roots.length > 1 || radioCount) {
      <mat-checkbox [formControl]="serial" class="mt-2"
        >{{ 'hlds.algorithm-tree-edit.serial' | translate }}
      </mat-checkbox>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button (click)="save()">
    {{ 'shared.button.save' | translate }}
  </button>
</mat-dialog-actions>
