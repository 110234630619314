<div class="order-view router-outlet">
  @if (!iframe) {
    <hlds-route-title [title]="'hlds.order.back' | translate" [route]="routePath.orders"></hlds-route-title>
  }

  <div class="flex flex-col flex-1 debxx yellow">
    <mat-tab-group #tabGroup *ngIf="order" mat-stretch-tabs="false" [selectedIndex]="selectedIndex" dynamicHeight>
      <mat-tab [label]="'hlds.order.status.title' | translate">
        <ng-container *ngTemplateOutlet="order_"></ng-container>
      </mat-tab>
      <mat-tab [label]="algorithm.name">
        <ng-container *ngTemplateOutlet="tree_"></ng-container>
      </mat-tab>
      <mat-tab [label]="patient.name">
        <ng-container *ngTemplateOutlet="patientDemographics_"></ng-container>
      </mat-tab>
      <mat-tab [label]="'hlds.algorithm.tests.title' | translate">
        <ng-container *ngTemplateOutlet="orderLabs_"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- demographics -->
  <ng-template #patientDemographics_>
    <mat-card class="debxx blue">
      <mat-card-content>
        <div class="patientData">
          <mat-form-field class="read-only no-gap">
            <mat-label>{{ 'hlds.patient.table.col.age' | translate }}</mat-label>
            <input matInput [value]="patient.age" readonly />
          </mat-form-field>
          <mat-form-field class="read-only no-gap">
            <mat-label>{{ 'hlds.patient.table.col.gender' | translate }}</mat-label>
            <input matInput [value]="patient.gender" readonly />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <!-- tree -->
  <ng-template #tree_>
    <hlds-node-view [nodes]="algorithm.tree.nodes" [nodeHighlight$]="nodeHighlight$"></hlds-node-view>
    <div class="mt-4 flex flex-row justify-end">
      <button mat-flat-button>{{ 'shared.button.print' | translate }}</button>
    </div>
  </ng-template>

  <!-- order panel -->
  <ng-template #order_>
    <mat-card>
      <mat-card-content>
        <ng-container *ngTemplateOutlet="orderAncestors_"></ng-container>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <!-- order labs -->
  <ng-template #orderLabs_>
    <hlds-generic-table [rows]="labsModel.labs" [items]="labItems" [options]="options"></hlds-generic-table>
  </ng-template>

  <!-- labs/Tests template -->
  <ng-template #labsTemplate let-row="row">
    @if (done !== 'done-order') {
      <div class="flex flex-row space-x-2">
        <button mat-icon-button [matTooltip]="'hlds.order.tooltip.reset' | translate" (click)="actionLab('reset', row)">
          <mat-icon>{{ 'shared.icon.reset' | translate }}</mat-icon>
        </button>
      </div>
    }
  </ng-template>

  <!-- order ancestors -->
  <ng-template #orderAncestors_>
    <div class="flex flex-col ml-4 space-y-2 mb-4">
      @for (ancestorNodes of treeAncestorNodes; track $index) {
        <hlds-generic-table [rows]="ancestorNodes" [items]="treeAncestorItems" [options]="options"></hlds-generic-table>
      }
      @if (order.nodeStates.length) {
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'hlds.order.history' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hlds-generic-table [rows]="order.nodeStates" [items]="nodeStateItems" [options]="nodeStateOptions">
            </hlds-generic-table>
          </mat-expansion-panel>
        </mat-accordion>
      }
    </div>
    <div class="mb-4">
      <mat-divider></mat-divider>
    </div>
    @if (done === 'done-order') {
      <div class="flex flex-row items-center bold-row">
        <div class="text-xl">{{ status }}</div>
        @if (order.disposition) {
          <div>&nbsp;({{ order.disposition }})</div>
        }
      </div>
    } @else {
      <div class="flex flex-row justify-end space-x-2">
        <button mat-button [matTooltip]="'hlds.order.tooltip.cancel' | translate" (click)="actionDisposition('cancel')">
          {{ 'hlds.order.button.cancel' | translate }}
        </button>

        @if (done === 'done-node') {
          <button mat-flat-button (click)="actionInterp()">
            {{ 'hlds.order.button.interpretation' | translate }}
          </button>
        }
      </div>
    }
  </ng-template>

  <!-- order template -->
  <ng-template #orderTemplate let-node="row" let-item="item">
    <span *ngIf="safeNode(node)" class="tree-table" [ngClass]="{ lastRow: lastRows.includes(node) }">
      @switch (item.key) {
        @case ('testValues') {
          @if (nodeEngine.type === 'cond' || nodeEngine.type === 'lab_panel') {
            @if (nodeEngine.testValues.length === 1) {
              {{ nodeEngine.testValues[0] }}
            } @else {
              @for (value of nodeEngine.testValues; track $index) {
                {{ nodeEngine.varNames[$index] }}&equals;{{ value }}<br />
              }
            }
          }
        }
        @case ('icon') {
          <mat-icon>{{ 'hlds.algorithm-node.icon.' + node.type | translate }}</mat-icon>
        }
        @case ('label') {
          @if (nodeEngine.type === 'cond') {
            <span>{{ nodeEngine.cond }}</span>
          } @else {
            <span>{{ nodeEngine.label }}</span>
          }
        }
        @case ('condIsTrue') {
          @if (nodeEngine.type === 'cond' && nodeEngine.testValues.length && nodeEngine.testValues[0] !== '') {
            {{ nodeEngine.condIsTrue.toString() | titlecase }}
          }
          @if (lastRows.includes(node)) {
            @if (isOrderNode()) {
              {{ 'hlds.order.state.' + (lastOrderState.nodeStatus || lastOrderState.nodeState) | translate }}
            } @else {
              @switch (nodeEngine.type) {
                @case ('cond') {
                  @if (!autoReflex(node)) {
                    {{ 'hlds.algorithm-node.not-autoReflex' | translate }}
                  }
                }
                @case ('lab_panel') {
                  @if (!autoReflex(node)) {
                    {{ 'hlds.algorithm-node.not-autoReflex' | translate }}
                  }
                }
                @case ('comment') {
                  @if (!autoReflex(node)) {
                    {{ 'hlds.algorithm-node.noteRequired' | translate }}
                  }
                }
              }
            }
          }
        }
        @case ('action-labs') {
          @if (lastRows.includes(node)) {
            @if (queryRequired(nodeEngine)) {
              <ng-container *ngTemplateOutlet="buttonQuery_"></ng-container>
              <ng-container *ngTemplateOutlet="buttonCancel_"></ng-container>
            } @else {
              @if (isOrderNode()) {
                @switch (lastOrderState.nodeState) {
                  @case ('node_active') {
                    <ng-container *ngTemplateOutlet="buttonPause_"></ng-container>
                    <ng-container *ngTemplateOutlet="buttonCancel_"></ng-container>
                  }
                  @case ('node_paused') {
                    <ng-container *ngTemplateOutlet="buttonPlay_"></ng-container>
                    <ng-container *ngTemplateOutlet="buttonCancel_"></ng-container>
                  }
                }
              } @else {
                @if (nodeEngine.type === 'cond' || nodeEngine.type === 'comment' || nodeEngine.type === 'lab_panel') {
                  @if (autoReflex(nodeEngine)) {
                    <ng-container *ngTemplateOutlet="buttonPause_"></ng-container>
                  } @else {
                    <ng-container *ngTemplateOutlet="buttonPlay_"></ng-container>
                  }
                }
                <ng-container *ngTemplateOutlet="buttonCancel_"></ng-container>
              }
            }
          } @else {
            @if (done !== 'done-order' && node.type !== 'stop') {
              <button mat-icon-button (click)="actionNote(node)" [matTooltip]="'hlds.order.tooltip.note' | translate">
                <mat-icon [ngClass]="{ 'note-exists': noteFound(node) }">{{
                  'shared.icon.comment' | translate
                }}</mat-icon>
              </button>
            }
          }
        }
      }

      <ng-template #buttonCancel_>
        <button
          mat-icon-button
          [matTooltip]="'hlds.order.tooltip.cancel-partial' | translate"
          (click)="actionOrder('cancel', node)"
        >
          <mat-icon>{{ 'hlds.order.icon.cancel' | translate }}</mat-icon>
        </button>
      </ng-template>
      <ng-template #buttonPause_>
        <button
          mat-icon-button
          [matTooltip]="'hlds.order.tooltip.pause' | translate"
          (click)="actionOrder('pause', node)"
        >
          <mat-icon>{{ 'hlds.order.icon.pause' | translate }}</mat-icon>
        </button>
      </ng-template>
      <ng-template #buttonPlay_>
        <button
          mat-icon-button
          [matTooltip]="'hlds.order.tooltip.play' | translate"
          (click)="actionOrder('continue', node)"
        >
          <mat-icon>{{ 'hlds.order.icon.play' | translate }}</mat-icon>
        </button>
      </ng-template>
      <ng-template #buttonQuery_>
        <button
          mat-icon-button
          class="query"
          [matTooltip]="'hlds.order.tooltip.query' | translate"
          (click)="actionQuery(node)"
        >
          <mat-icon>{{ 'shared.icon.quiz' | translate }}</mat-icon>
        </button>
      </ng-template>
    </span>
  </ng-template>

  <!-- node.nodeStates -->
  <ng-template #nodeStatesTemplate let-state="row" let-item="item">
    <ng-container *ngIf="safeOrderNodeState(state)">
      @switch (item.key) {
        @case ('nodeState') {
          <span>{{ 'hlds.order.state.' + (nodeStateRow.nodeStatus || nodeStateRow.nodeState) | translate }}</span>
        }
      }
    </ng-container>
  </ng-template>
</div>
