import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { settingsDateFormats, SettingsI18nModel } from '../../models/settings-algorithms.model';
import { I18nService } from '../../services/i18n/i18n.service';
import { SettingsService } from '../../services/settings/settings.service';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';

@Component({
  selector: 'hlds-settings-i18n',
  templateUrl: './settings-i18n.component.html',
})
export class SettingsI18nComponent extends BaseFormDirective<SettingsI18nModel> implements OnInit {
  readonly languages = inject(I18nService).languages;
  private readonly data = inject(SettingsService).data;

  constructor() {
    super({ nonNullable: true });
  }

  ngOnInit() {
    const { data, languages } = this;

    this.formCreate({
      date: data.i18n?.date || settingsDateFormats[0],
      language: data.i18n?.language || languages[0].ext,
    });

    const {
      destroyRef,
      form,
      controls: { date, language },
    } = this;

    if (!settingsDateFormats.includes(date.value)) {
      date.setValue(settingsDateFormats[0]);
    }

    if (!languages.find((l) => l.ext == language.value)) {
      language.setValue(languages[0].ext);
    }

    form.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => (data.i18n = this.formValue));
  }
}
