import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { OrderQueryComponentModel, OrderQueryComponentRes } from './order-query.component.model';

@Component({
  templateUrl: './order-query.component.html',
})
export class OrderQueryComponent extends BaseFormDirective<OrderQueryComponentRes> implements OnInit {
  data: OrderQueryComponentModel = inject(MAT_DIALOG_DATA);
  constructor() {
    super({ allRequired: true });
  }

  ngOnInit() {
    this.formCreate({ response: '' });
  }
}
