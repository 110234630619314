<hlds-dialog-drawer-close [heading]="title"></hlds-dialog-drawer-close>
<mat-dialog-content class="flex-important flex-col">
  <mat-form-field>
    <mat-label>{{ 'hlds.order-note.note' | translate }}</mat-label>
    <textarea matInput [formControl]="controls.note" required [cdkTextareaAutosize]="true"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" class="space-x-2">
  <button [mat-dialog-close]="null" mat-button cdkFocusInitial>
    {{ 'shared.button.cancel' | translate }}
  </button>
  <button mat-flat-button [disabled]="!form.valid" [mat-dialog-close]="formValue">
    {{ 'shared.button.ok' | translate }}
  </button>
</mat-dialog-actions>
