<div class="algorithm-list router-outlet">
  <hlds-route-title [title]="'hlds.algorithms.title' | translate"></hlds-route-title>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
    @for (item of tabs; track item.key) {
      <a mat-tab-link (click)="tabActive = item.key" [active]="tabActive === item.key">{{
        item.value.label || '' | translate
      }}</a>
    }
  </nav>
  <div class="flex-1" [ngClass]="{ 'mt-4': !options.add }">
    <hlds-generic-table [items]="items" [rows]="rows" [options]="options" (run)="action($event)"></hlds-generic-table>
  </div>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  <ng-template #actionTemplate let-row="row">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent>
        @for (act of row.actions; track act.action) {
          <button mat-menu-item (click)="action({ action: act.action, row: row, rowIndex: -1 })">
            <mat-icon>{{ act.icon | translate }}</mat-icon>
            <span>{{ 'hlds.algorithm.action.' + act.action | translate }}</span>
          </button>
        }
      </ng-template>
    </mat-menu>
  </ng-template>
</div>
